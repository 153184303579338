const initialState = {
	courses: [],
};

export const CONSTANTS = {
	REMOVE_COURSE: "REMOVE_COURSE",
	SET_COURSES: "SET_COURSES",
	ADD_COURSE: "ADD_COURSE",
	LOGOUT: "LOGOUT",
};

const coursesReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.REMOVE_COURSE: {
			return {
				...state,
				courses: state.courses.filter((course) => course.id !== action.payload),
			};
		}
		case CONSTANTS.SET_COURSES: {
			return {
				...state,
				courses: action.payload,
			};
		}
		case CONSTANTS.ADD_COURSE: {
			return {
				...state,
				courses: [...state.courses, action.payload],
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default coursesReducer;