import { useState } from "react";
import { AsyncPost } from "Global/api";
import { useTranslation } from "utilities/hooks";

import "./errorToast.scss";

const ErrorToast = () => {
	const [isToastOpen, setIsToastOpen] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [translate] = useTranslation("errorToast");

	window.onerror = function (msg, url, lineNo, columnNo, error) {
		localStorage.setItem(
			"unhandledError",
			JSON.stringify({
				frontend_url: window.location.href,
				message: msg,
				url: url,
				lineNo: lineNo,
				columnNo: columnNo,
				stack: error && error.stack ? error.stack : "",
			})
		);
		// TODO: Define when toast error needs to show, when in PRD environment

		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.error("SS: UnhandledError in Development. No report was sent!");
			setIsToastOpen(true);
			setTimeout(() => {
				setIsToastOpen(false);
			}, 10000);
			setErrorMsg(`ERROR: ${msg}`);
		} else {
			AsyncPost("/notification/email-admin/", {
				body: JSON.stringify({
					frontend_url: window.location.href,
					message: msg,
					url: url,
					lineNo: lineNo,
					columnNo: columnNo,
					stack: error && error.stack ? error.stack : "",
				}),
				subject: "SkillSuite Frontend Error",
			});
		}
		console.log(msg, url, lineNo, columnNo, error);
		return true;
	};

	const handleCloseToast = () => {
		setIsToastOpen(false);
	};

	if (!isToastOpen) {
		return null;
	}

	return (
		<div className="error-toast-container">
			<p className="error-toast-close-btn" onClick={handleCloseToast}>
				X
			</p>
			<p className="error-toast-title">{translate("title")}</p>
			<p className="error-toast-description">{translate("description")}</p>
			{errorMsg !== "" ? (
				<p className="error-toast-action">{errorMsg}</p>
			) : (
				<p className="error-toast-action">{translate("actionDescription")}</p>
			)}
		</div>
	);
};

export default ErrorToast;
