/** @format */
import React, { useState } from "react";
import error500 from "assets/images/error500.jpg";
import { AsyncPost } from "Global/api";
import { errorToast } from "Global/functions";
import { logoutUser } from "store/auth/actions";

const Page500 = () => {
	const [errorSent, setErrorSent] = useState(false);
	const sendError = () => {
		if (!errorSent) {
			let defaultObject = {
				...JSON.parse(
					localStorage.getItem("unhandledError")
						? localStorage.getItem("unhandledError")
						: "{}"
				),
				previousUrl: document.referrer,
				user: sessionStorage.getItem("userData") || "",
				platform: window.navigator.platform,
				vendor: window.navigator.vendor,
			};

			AsyncPost("/notification/email-admin/", {
				body: JSON.stringify(defaultObject),
				subject: "Skillsuite Frontend 500 Error",
			})
				.then(() => console.log("Error submitted!"))
				.catch(() => {
					errorToast(
						"Error!",
						"There was an issue submitting your error, please contact customer service for support."
					);
					setErrorSent(false);
				});
			setErrorSent(true);
		}
	};

	const clearData = () => {
		logoutUser();
		localStorage.clear();
		window.location.href = "/logout";
	};

	return (
		<div className="error-500-page">
			<img
				className="col-12 col-md-6 p-0 position-absolute errorImg"
				src={error500}
				alt="500"
			/>
			<div className="errorMsg position-absolute ">
				<h2 className="font-weight-bold display-4">Uh, oh!</h2>
				<h4>Something went really wrong on our end.</h4>
				<p className="mt-4 text-muted">
					Don’t worry, it’s not you—it’s us. Sorry about that.
				</p>
				{/* prettier-ignore */}
				<div className="mt-4">
					<a href="/" className="btn btn-regular ml-0">Back to Home</a>
					<button onClick={ clearData } className="btn btn-regular">Clear Data</button>
				</div>
				<div className="send-error-to-admin mt-4 text-right">
					<small>Would you like to report this error?</small>
					<button
						disabled={errorSent}
						type="button"
						className=" btn btn-small mt-0 mb-0 mr-0 ml-2"
						onClick={sendError}>
						{errorSent ? "Error Submitted" : "Report"}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Page500;
