/** @format */

import Toast from "oldDirectory/components/objects/Toasts";
import { toast } from "react-toastify";
import success from "assets/images/icons/success.png";
import error from "assets/images/icons/error.png";
import info from "assets/images/icons/info-icon.svg";
import { AwaitGet } from "./api";
import { useSelector } from "redux/index";
import _ from "lodash";
import themeColors from "assets/css/js-exports/_theme-colors.module.scss";
import { components } from "react-select";
import PropTypes from "prop-types";

export const toggleDisplay = (query, display) => {
	var x = document.querySelector(query);
	if (x.style.display === "none") {
		return (x.style.display = display || "block");
	} else {
		return (x.style.display = "none");
	}
};

export const successToast = (title, subtitle) =>
	toast.success(<Toast title={title} subtitle={subtitle} image={success} />);
export const errorToast = (title, subtitle) =>
	toast.error(<Toast title={title} subtitle={subtitle} image={error} />);
export const warningToast = (title, subtitle) =>
	toast.warn(<Toast title={title} subtitle={subtitle} image={info} />);

export const loadOptions = async (
	search,
	prevOptions,
	{ url, page, searchUrl, searchPage, prevSearch, labelName, id, user }
) => {
	let options = [];
	let hasMore = true;
	if (search !== prevSearch) searchPage = 1;
	let apiUrl =
		searchUrl + search + `&simple=true&page_size=10&page=${searchPage}`;
	if (!search) apiUrl = url + `&simple=true&page=${page}`;
	let response = await AwaitGet(apiUrl, false);
	if (!labelName) labelName = "label";

	let results = response?.data?.results || response?.data || response;

	if (Array.isArray(results) === false && typeof results === "object")
		results = Object.keys(results).map((key) => ({
			id: key,
			...results[key],
		}));

	_.map(results, (data) => {
		let option = { ...data };
		if (labelName) option.label = data[labelName] || data.label || data.email;
		if (user)
			option.label =
				data.first_name && data.last_name
					? data.first_name + " " + data.last_name
					: data.email;
		if (id) option.id = data[id] || data.id;
		options.push(option);
	});

	hasMore = response.data.page < response.data.pages;
	return {
		options,
		hasMore: hasMore,
		additional: {
			page: page + 1,
			url: url,
			searchUrl: searchUrl,
			searchPage: searchPage,
			prevSearch: search,
			prevResults: response.data.results,
			labelName,
			id,
			user,
		},
	};
};

export const loadMultiOptions = async (
	search,
	prevOptions,
	{ url, page, searchUrl, searchPage, label }
) => {
	let labelOptions = ["users", "tracks"];
	const options = new Set();
	let hasMore;
	let apiUrl =
		searchUrl + search + `&simple=true&page_size=10&page=${searchPage}`;
	if (!search) apiUrl = url + `&simple=true&page=${page}`;
	let res = await AwaitGet(apiUrl);
	hasMore = res.data.page < res.data.pages;

	if (labelOptions.includes(label)) {
		_.map(res.data.results, (x) => {
			let name = x.email;
			x.first_name && (name = x.first_name + " " + x.last_name);
			options.add({ value: x.id, label: name, data: x });
		});
	} else {
		_.map(res.data.results, (x) => {
			options.add({ value: x.id, label: x.label, data: x });
		});
	}

	return {
		options: Array.from(options),
		hasMore,
		additional: {
			page: page + 1,
			url: url,
			searchUrl: searchUrl,
			searchPage: searchPage,
			prevSearch: search,
			label,
		},
	};
};

export const scrollToTop = () => {
	let container = document.body;
	container.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth",
	});
};

export const animateValue = (id, start, end, duration) => {
	var range = end - start;
	var current = start;
	var increment = end > start ? 1 : -1;
	var stepTime = Math.abs(Math.floor(duration / range));

	if (stepTime === 0) {
		stepTime = 0.005;
	}

	if (range > duration) {
		increment = Math.floor(range / duration);
	}
	var obj = document.getElementById(id);
	let value = parseInt(obj ? obj.textContent : "0");
	if (value <= start) {
		var timer = setInterval(function () {
			current += increment;
			if (current + increment > end) {
				current = end;
			}

			if (obj && obj.textContent)
				obj.textContent = current ? current : `${end ? end : "0"}`;

			if (current === end) {
				clearInterval(timer);
			}
		}, stepTime);
	}
};

export const getOrgNode = (id, hierarchy) => {
	for (let i = 0; i < hierarchy.length; i++) {
		if (hierarchy[i].id === id) {
			return hierarchy[i];
		} else if (hierarchy[i].children.length > 0) {
			let orgNode = getOrgNode(id, hierarchy[i].children);
			if (orgNode.id === id) {
				return orgNode;
			}
		}
	}
	return false;
};

export const customSelectStyles = {
	option: (provided, state) => {
		const isSelected = !_.isNil(
			_.find(state.getValue(), ["id", state.data.id])
		);
		if (!state.isMulti) {
			return {
				...provided,
				cursor: "pointer",
				borderLeft: isSelected ? `5px solid ${themeColors["primary"]}` : null,
				color: isSelected ? themeColors["primary"] : "#00000",
				backgroundColor: "#fff!important",
				opacity: state.isDisabled ? "0.5" : "1",
				pointerEvents: state.isDisabled ? "none" : "all",
				"&:hover, &:active": {
					borderLeft: `5px solid ${themeColors["primary"]}`,
					color: themeColors["primary"],
					backgroundColor: "#fff!important",
					"svg, svg *": {
						fill: `${themeColors["primary"]}!important`,
					},
				},
				"svg, svg *": {
					fill: isSelected ? `${themeColors["primary"]}!important` : "#96999C",
				},
			};
		} else {
			return {
				...provided,
				cursor: "pointer",
				borderLeft: isSelected ? `5px solid ${themeColors["primary"]}` : null,
				color: isSelected ? themeColors["primary"] : "#000000",
				backgroundColor: "#fff!important",
				"&:hover": {
					borderLeft: `5px solid ${themeColors["primary"]}`,
					color: themeColors["primary"],
					backgroundColor: "#fff!important",
					"svg, svg *": {
						fill: `${themeColors["primary"]}!important`,
					},
				},
				"svg, svg *": {
					fill: isSelected ? `${themeColors["primary"]}!important` : "#96999C",
				},
			};
		}
	},
	control: (provided, state) => ({
		...provided,
		cursor: "pointer",
		boxShadow: "none",
		borderColor: state.isFocused ? themeColors["primary"] : "hsl(0,0%,80%)",
		"&:hover, &:focus": {
			borderColor: state.isFocused ? themeColors["primary"] : "hsl(0,0%,80%)",
		},
	}),
	placeholder: (provided) => ({
		...provided,
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
	}),
	container: (provided) => ({
		...provided,
	}),
	menu: (provided) => ({
		...provided,
		zIndex: "500",
		overflow: "visible",
	}),
};

// Same as customSelectStyles, but allows the primary color to be passed as a parameter
export const getCustomSelectStyles = () => {
	return {
		option: (provided, state) => {
			const isSelected = !_.isNil(
				_.find(state.getValue(), ["id", state.data.id])
			);
			if (!state.isMulti) {
				return {
					...provided,
					cursor: "pointer",
					borderLeft: isSelected
						? "5px solid var(--main-color) !important"
						: null,
					color: isSelected ? "var(--main-color) !important" : "#00000",
					backgroundColor: "#fff !important",
					opacity: state.isDisabled ? "0.5" : "1",
					pointerEvents: state.isDisabled ? "none" : "all",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					"&:hover, &:active": {
						borderLeft: "5px solid var(--main-color) !important",
						color: "var(--main-color) !important",
						backgroundColor: "#fff!important",
						"svg, svg *": {
							fill: "var(--main-color) !important",
						},
					},
					"svg, svg *": {
						fill: isSelected ? "var(--main-color) !important" : "#96999C",
					},
				};
			} else {
				return {
					...provided,
					cursor: "pointer",
					borderLeft: isSelected
						? "5px solid var(--main-color) !important"
						: null,
					color: isSelected ? "var(--main-color) !important" : "#000000",
					backgroundColor: "#fff !important",
					"&:hover": {
						borderLeft: "5px solid var(--main-color)",
						color: "var(--main-color)",
						backgroundColor: "#fff !important",
						"svg, svg *": {
							fill: "var(--main-color) !important",
						},
					},
					"svg, svg *": {
						fill: isSelected ? "var(--main-color) !important" : "#96999C",
					},
				};
			}
		},
		control: (provided, state) => ({
			...provided,
			cursor: "pointer",
			boxShadow: "none",
			borderColor: state.isFocused
				? "var(--main-color) !important"
				: "hsl(0,0%,80%)",
			"&:hover": {
				borderColor: state.isFocused
					? "var(--main-color) !important"
					: "hsl(0,0%,80%)",
			},
			"&:focus": {
				borderColor: state.isFocused
					? "var(--main-color) !important"
					: "hsl(0,0%,80%)",
			},
		}),
		placeholder: (provided) => ({
			...provided,
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
		}),
		container: (provided) => ({
			...provided,
		}),
		menu: (provided) => ({
			...provided,
			zIndex: "500",
			overflow: "visible",
		}),
	};
};

export const GetPermission = (permission) => {
	const userPermissions = GetUserPermissions();
	let hasPermissions = false;

	if (typeof permission === "string" && userPermissions[permission]) {
		hasPermissions = true;
	} else if (typeof permission === "object" && permission.length > 0) {
		for (let i = 0; i < permission.length; i++) {
			if (userPermissions[permission[i]]) {
				hasPermissions = true;
			}
		}
	}

	return hasPermissions;
};

export const countdownTimer = (date, elementId, seperateElements) => {
	let countDownDate = new Date(date).getTime();

	let timeInterval = setInterval(function () {
		let now = new Date().getTime();

		let countdown = countDownDate - now;

		let days = Math.floor(countdown / (1000 * 60 * 60 * 24));
		let hours = Math.floor(
			(countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		let minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
		let seconds = Math.floor((countdown % (1000 * 60)) / 1000);

		if (document.querySelector(`#${elementId}`)) {
			if (!seperateElements) {
				document.querySelector(`#${elementId}`).textContent =
					days + ":" + hours + ":" + minutes + ":" + seconds;
			} else {
				if (seperateElements.hours)
					document.querySelector(
						`#${elementId} ${seperateElements.hours}`
					).textContent = hours;
				if (seperateElements.minutes)
					document.querySelector(
						`#${elementId} ${seperateElements.minutes}`
					).textContent = minutes;
				if (seperateElements.days)
					document.querySelector(
						`#${elementId} ${seperateElements.days}`
					).textContent = days;
				if (seperateElements.seconds)
					document.querySelector(
						`#${elementId} ${seperateElements.seconds}`
					).textContent = seconds;
			}
		}

		if (countdown < 0) {
			clearInterval(timeInterval);
		}
	}, 1000);
};

/**
 * @param {string} date
 * @param {object} [options={month: "short", day: "numeric", year: "numeric"}]
 * @returns {string} formatted 'July, 21, 1998'
 */
export const formatStringDate = (
	date,
	options = { month: "short", day: "numeric", year: "numeric" }
) => {
	return date
		? new Intl.DateTimeFormat("en-US", options).format(new Date(date))
		: "";
};

export const updatedLast = (date) => {
	const seconds = Math.floor((new Date() - date) / 1000);
	let interval = seconds / 31536000;
	if (interval > 1) {
		return Math.floor(interval) + " years";
	}
	interval = seconds / 2592000;
	if (interval > 1) {
		return Math.floor(interval) + " months";
	}
	interval = seconds / 86400;
	if (interval > 1) {
		return Math.floor(interval) + " days";
	}
	interval = seconds / 3600;
	if (interval > 1) {
		return Math.floor(interval) + " hours";
	}
	interval = seconds / 60;
	if (interval > 1) {
		return Math.floor(interval) + " minutes";
	}
	return Math.floor(seconds) + " seconds";
};

export const formatDate = (date) => {
	let d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	return [month, day, year].join("/");
};

export const labelSort = (a, b) => {
	const labelA = a.label.toUpperCase();
	const labelB = b.label.toUpperCase();
	let comparison = 0;

	if (labelA > labelB) {
		comparison = 1;
	} else if (labelA < labelB) {
		comparison = -1;
	}
	return comparison;
};

export const reverseLabelSort = (a, b) => {
	const labelA = a.label.toUpperCase();
	const labelB = b.label.toUpperCase();

	let comparison = 0;
	if (labelA < labelB) {
		comparison = 1;
	} else if (labelA > labelB) {
		comparison = -1;
	}
	return comparison;
};

export const dateCreatedSort = (a, b) => {
	const dateA = new Date(a.date_created);
	const dateB = new Date(b.date_created);

	let comparison = 0;
	if (dateA.valueOf() > dateB.valueOf()) {
		comparison = 1;
	} else if (dateA.valueOf() < dateB.valueOf()) {
		comparison = -1;
	}
	return comparison;
};

export const reverseDateCreatedSort = (a, b) => {
	const dateA = new Date(a.date_created);
	const dateB = new Date(b.date_created);

	let comparison = 0;
	if (dateA.valueOf() < dateB.valueOf()) {
		comparison = 1;
	} else if (dateA.valueOf() > dateB.valueOf()) {
		comparison = -1;
	}
	return comparison;
};

export const searchFilter = (searchString, array, labelKey) => {
	let newArray = [];
	let searchArray = searchString.split(" ");
	searchArray = searchArray.filter(function (el) {
		if (el !== "") return true;
		else return false;
	});
	const searchSort = (a, b) => {
		const pointValueA = a.pointValue;
		const pointValueB = b.pointValue;

		let comparison = 0;
		if (pointValueA < pointValueB) {
			comparison = 1;
		} else if (pointValueA > pointValueB) {
			comparison = -1;
		}
		return comparison;
	};

	if (searchArray && searchArray.length > 0) {
		_.map(array, (object) => {
			let pointValue = 0;
			let arrayTerms = [];

			let objectKeys = Object.keys(object);
			for (let i = 0; i < objectKeys.length; i++) {
				if (typeof object[objectKeys[i]] === "string") {
					arrayTerms.push(...object[objectKeys[i]].split(" "));
				}
			}
			let label = object[labelKey] || object.label || "";
			label = label.toUpperCase();

			for (let i = 0; i < searchArray.length; i++) {
				let currentSearchTerm = searchArray[i].toUpperCase();

				for (let x = 0; x < arrayTerms.length; x++) {
					if (currentSearchTerm === arrayTerms[x].toUpperCase()) {
						pointValue += 3;
					} else if (
						arrayTerms[x].toUpperCase().indexOf(currentSearchTerm) === 0
					) {
						pointValue += 0.5;
					}
				}
				let values = arrayTerms.join(" ").toUpperCase();
				if (values.indexOf(currentSearchTerm) > -1) {
					let currentIndex =
						values.indexOf(currentSearchTerm) > 0
							? values.indexOf(currentSearchTerm)
							: 1;
					let pointsToAdd = 2 + currentSearchTerm.length / currentIndex;
					if (label.indexOf(currentSearchTerm) === 0) pointsToAdd += 2;

					pointValue += pointsToAdd;
				}
			}
			if (searchArray.join(" ").toUpperCase() === label.toUpperCase()) {
				pointValue += 1000;
			}
			if (pointValue > 0) {
				newArray.push({ ...object, pointValue: pointValue });
			}
		});

		return newArray.sort(searchSort);
	} else return array;
};
/**
 * Custom css overrides for React Select
 * @see https://react-select.com/styles#style-object
 */
export const sortByStyles = {
	control: (provided) => ({
		...provided,
		color: "black",
		borderStyle: null,
		boxShadow: null,
		"&:hover": {
			...provided["&:hover"],
			borderStyle: null,
			boxShadow: null,
		},
		"&:focus": {
			...provided["&:focus"],
			borderStyle: null,
			boxShadow: null,
		},
		fontSize: "1.2rem",
		justifyContent: "start",
		cursor: "pointer",
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		color: "black",
	}),
	indicatorSeparator: () => null,
	placeholder: (provided) => ({ ...provided, color: "black" }),
	menu: (provided) => ({
		...provided,
		minWidth: "225px",
		padding: "10px 0",
		right: "8px",
	}),
	clearIndicator: (provided) => ({
		...provided,
		position: "absolute",
		color: "black",
		left: "-25%",
		top: "50%",
		transform: "translateY(-50%)",
	}),
	valueContainer: (provided) => ({
		...provided,
		overflow: "visible",
		flexWrap: "nowrap",
		whiteSpace: "nowrap",
	}),
	option: (provided, state) => {
		const { isSelected } = state;
		return {
			...provided,
			cursor: "pointer",
			borderLeft: isSelected ? `5px solid ${themeColors["primary"]}` : null,
			color: isSelected ? themeColors["primary"] : "#000",
			backgroundColor: "#fff!important",
			"&:hover": {
				borderLeft: `5px solid ${themeColors["primary"]}`,
				color: isSelected ? themeColors["primary"] : "#000",
				backgroundColor: "#fff!important",
			},
		};
	},
};

export function shuffle(array) {
	var currentIndex = array.length,
		temporaryValue,
		randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
}

export function createCookie(
	name,
	value,
	days,
	path,
	deleteCookie = false,
	subDomainSpecfic = false
) {
	let expires = "";
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toGMTString();
	}
	if (!path) path = "/";

	let domain = window.location.hostname;
	if (subDomainSpecfic)
		domain = window.location.host;

	document.cookie =
		name +
		"=" +
		value +
		"; domain=" +
		domain +
		expires +
		"; path=" +
		path +
		";  samesite=lax;";

	//prettier-ignore
	if (deleteCookie && (window.location.hostname.includes("local.skillsuite.com") || window.location.hostname.includes("dev.skillsuite.com")))
		document.cookie = name + "=" + value + "; domain=" + domain + expires + "; path=" + path + ";  samesite=lax;";
}

export function readCookie(name) {
	let nameEQ = name + "=";
	let ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}
	return false;
}

export function eraseCookie(name) {
	createCookie(name, "", -1, "/", true);
}

export function deleteAllCookies() {
	let cookies = document.cookie.split(";");

	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i];
		let eqPos = cookie.indexOf("=");
		let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		name = name.split(" ").join("");
		document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
	}
}

export function sendPageViewToGoogle(location, attemptsLeft = 3) {
	if (window.gtag && typeof window.gtag === "function") {
		window.gtag("event", "page_view", {
			page_title: document.title,
			page_location: window.location.href,
			page_path: window.location.pathname,
			send_to: process.env.GOOGLE_TRACKING_CODE,
		});
	} else if (attemptsLeft > 0) {
		window.setTimeout(() => sendPageViewToGoogle(attemptsLeft - 1), 500);
	} else {
		console.log("google timed out");
	}
}

/**
 * @param timeString - HH:MM:SS (24hr)
 * @returns {string} - HH:MM (AM|PM) (12hr)
 */
export const formatTimeString = (timeString) => {
	let [hrs, mins] = _.split(timeString, ":", 2);
	hrs = _.toInteger(hrs);
	return `${hrs < 13 ? hrs : hrs - 12}:${mins}${hrs < 12 ? "AM" : "PM"}`;
};

export const getSubdomain = () => {
	let hostName = window.location.hostname;
	if (hostName.indexOf(".skillsuite") > -1) {
		return hostName.slice(0, hostName.indexOf(".skillsuite"));
	} else return null;
};

export const libraryDateUpdateFormat = (dateString, lastEditedBy) => {
	let date = new Date(dateString);
	let hours = date.getHours();
	let minutes = date.getMinutes();
	let ampm = hours >= 12 ? "pm" : "am";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	minutes = minutes < 10 ? "0" + minutes : minutes;
	let timeString = hours + ":" + minutes + " " + ampm;

	return `Last Updated ${dateFormat(dateString)} at ${timeString} ${
		lastEditedBy ? `by ${lastEditedBy}` : ""
	}`;
};

export const dateFormat = (dateString) => {
	let months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"June",
		"July",
		"Aug",
		"Sept",
		"Oct",
		"Nov",
		"Dec",
	];
	let date = new Date(dateString);

	return `${months[date.getMonth()]}. ${date.getDate()}, ${date.getFullYear()}`;
};

export const GetUserPermissions = () =>
	useSelector(({ permissions }) => permissions.permissions);

export const GetUser = () => useSelector(({ auth }) => auth.userData);

export const getErrorMessage = (error, defaultMessage) => {
	if (error.response) return getErrorMessage(error.response);
	else if (error.data) return getErrorMessage(error.data);
	else if (error.text)
		return getErrorMessage({ error: JSON.stringify(error.text) });
	else if (error.error || error.detail || _.head(error.non_field_errors))
		return `${error.error || error.detail || error.non_field_errors[0]}`;
	else return defaultMessage || "Oops! Looks like we ran into an issue!";
};

//custom option for async selects
export const Option = (props) => {
	let data = props.data;
	if (data.data) data = data.data;
	let optionChildren = props.children;
	if (data.org_node_label || data.entity_label)
		optionChildren = [
			...(data && [
				<div
					className="async-select-option-title"
					key={data.org_node_label || data.entity_label}>
					{data.org_node_label || data.entity_label}
				</div>,
			]),
			props.children,
		];

	return <components.Option {...props}>{optionChildren}</components.Option>;
};

Option.propTypes = {
	data: PropTypes.object.isRequired,
	children: PropTypes.node,
};

export const compareObjects = (object1, object2) => {
	let object1Keys = Object.keys(object1);
	let object2Keys = Object.keys(object2);
	let diff = _.xor(object1Keys, object2Keys);
	let isDifferent = diff.length > 0;

	for (let i = 0; i < object1Keys.length; i++) {
		if (isDifferent) break;
		if (object1[object1Keys[i]] !== object2[object1Keys[i]]) {
			isDifferent = true;
			break;
		}
	}
	return isDifferent;
};
/**
 * This takes the rules and counts how many are true and based of the # of elements that are true
 * then it will return the corresponding name
 * @params shared-rule: object
 */
export const sharedRule = (rule) => {
	const acceptedList = [
		"create_resource",
		"create_rules",
		"delete_resource",
		"delete_rules",
		"edit_resource",
		"edit_rules",
		"view_resource",
		"view_rules",
	];

	const rulesObj = _.reduce(
		rule,
		(rules, rule, key) => {
			if (_.includes(acceptedList, key) && rule) {
				return { ...rules, [key]: rule };
			} else {
				return rules;
			}
		},
		{}
	);
	switch (_.size(rulesObj)) {
		case 8:
			return "Co-Editor";
		case 5:
			return "editor";
		case 3:
			return "Uploader";
		default:
			return "Viewer";
	}
};

export function componentLoader(lazyComponent, attemptsLeft = 3) {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error) => {
				// let us retry after 1500 ms
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					componentLoader(lazyComponent, attemptsLeft - 1).then(
						resolve,
						reject
					);
				}, 1500);
			});
	});
}

export const validatePhoneNumber = (elementValue) => {
	var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	return phoneNumberPattern.test(elementValue);
};

export const formatPhoneNumber = (number) => {
	var cleaned = ("" + number).replace(/\D/g, "");
	var match = cleaned.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
	if (match && match[2])
		return `${match[2] ? `(${match[1]}) ${match[2]}` : ""}${
			match[3] ? `-${match[3]}` : ""
		}`;
	else return number;
};

export const standardNumberFormat = (areaCode, number) =>
	(areaCode || "+1") + ("" + number).replace(/\D/g, "");

export function showCalendly(onEventScheduled) {
	new Function(
		"Calendly.initPopupWidget({url: 'https://calendly.com/zach_skillsuite/discovery-call'});return false;"
	)();

	function calendlyEventHandler(event) {
		if (event.data && event.data.event === "calendly.event_scheduled") {
			window.gtag("event", "conversion", {
				send_to: "AW-472427861/qAaICJbexesBENXaouEB",
			});
			if (onEventScheduled) onEventScheduled();
		}
	}
	window.removeEventListener("message", calendlyEventHandler);
	window.addEventListener("message", calendlyEventHandler);
}

export const logoutUser = () => {
	// if ("production" === process.env.NODE_ENV) {
	// 	userflow.reset();
	// }
	sessionStorage.clear();
	eraseCookie("jwtRefresh");
	eraseCookie("jwtAccess");
	eraseCookie("tree");
	eraseCookie("accounts");
	eraseCookie("subdomain");
	window.location.href = "/account/login/";
};

export const orderHierarchy = (tierList) => {
	let org_nodes = [...tierList];
	for (let i = 0; i < org_nodes.length; i++) {
		org_nodes.splice(i + 1, 0, ...org_nodes[i].children);
	}

	return org_nodes;
};
