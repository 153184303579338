import jwt from "jsonwebtoken";
import setAuthorizationToken from "../oldDirectory/components/account/setAuthorizationToken";
import axios from "axios";
import {
	createCookie,
	eraseCookie,
	readCookie,
	deleteAllCookies,
	logoutUser,
} from "./functions";

/**
 * @returns {boolean}
 */
export const refreshTokenValidity = () => {
	const refreshExp = jwt.decode(readCookie("jwtRefresh"));
	const now = Date.now().valueOf() / 1000;
	return refreshExp && now < refreshExp.exp;
};

/**
 * @param {function} func
 * @returns {boolean|*}
 */
export const accessTokenValidity = (func) => {
	const accessExp = jwt.decode(readCookie("jwtAccess"));
	const now = Date.now().valueOf() / 1000;
	if (accessExp && now < accessExp.exp) {
		setAuthorizationToken(readCookie("jwtAccess"));
		if (func) return func();
	} else if (refreshTokenValidity()) {
		axios
			.post("/user/api/token/refresh/", {
				refresh: readCookie("jwtRefresh"),
			})
			.then((res) => {
				eraseCookie("jwtAccess");
				createCookie("jwtAccess", res.data.access, 1);
				setAuthorizationToken(res.data.access);
				if (func) func();
			})
			.catch(logoutUser);
	} else {
		deleteAllCookies();
		return false;
	}
	return true;
};
