import { Redirect, Route } from "react-router-dom";
import { refreshTokenValidity } from "Global/authenticated";
import { deleteAllCookies } from "Global/functions";
import PropTypes from "prop-types";

function PrivateRoute(props) {
	const { children, ...rest } = props;

	const renderMethod = ({ location }) => {
		if (refreshTokenValidity()) {
			return children;
		} else {
			deleteAllCookies();
			return (
				<Redirect
					to={{
						pathname: "/account/login/",
						state: { from: location },
					}}
				/>
			);
		}
	};

	return <Route {...rest} render={renderMethod} />;
}

PrivateRoute.propTypes = {
	children: PropTypes.element,
};

export default PrivateRoute;
