import { CONSTANTS } from "./reducer";
import { AsyncGet } from "Global/api";

export const fetchHierarchy = () => async (dispatch, state) => {
	const tree_id = state?.auth?.active_tree?.id || null;

	try {
		dispatch({ type: CONSTANTS.HIERARCHY_REQUEST });

		const res = await AsyncGet("/organization/hierarchy/", 2, {
			in_tree: tree_id,
		});

		dispatch({ type: CONSTANTS.HIERARCHY_SUCCESS, payload: res.data.results });
	} catch (err) {
		console.error(err);
		dispatch({ type: CONSTANTS.HIERARCHY_CATCH, payload: err });
	}
};
