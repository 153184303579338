import uniqueId from "lodash/uniqueId";

export const CONSTANTS = {
	UPDATE_ACTIVE_MENU_OPTION: "UPDATE_ACTIVE_MENU_OPTION",
	UPDATE_FLOW_CHILD: "UPDATE_FLOW_CHILD",
	SET_INTEGRATIONS: "SET_INTEGRATIONS",
	ADD_FLOW_CHILD: "ADD_FLOW_CHILD",
	UPDATE_FLOW_ID: "UPDATE_FLOW_ID",
	UPDATE_FLOW: "UPDATE_FLOW",
	SET_FLOWS: "SET_FLOWS",
	ADD_FLOW: "ADD_FLOW",

	REORDER_CHILD: "REORDER_CHILD",
	DUPLICATE_CHILD: "DUPLICATE_CHILD",
	DELETE_CHILD: "DELETE_CHILD",
	LOGOUT: "LOGOUT",
};

const initialState = {
	activeMenuOption: "trigger",
	duplicatedChildId: null,
	flows: [],
	highlightedFlowId: null,
	integrations: [],
};

const flowsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.SET_INTEGRATIONS: {
			return {
				...state,
				integrations: action.payload,
			};
		}
		case CONSTANTS.UPDATE_ACTIVE_MENU_OPTION: {
			const { highlightedFlowId = null, activeMenuOption } = action.payload;

			return {
				...state,
				activeMenuOption,
				highlightedFlowId,
			};
		}
		case CONSTANTS.UPDATE_FLOW_CHILD: {
			const { id, data } = action.payload;
			const index = state.flows.findIndex((item) => item.id === id);

			try {
				const nodeIndex = state.flows[index].children.findIndex(
					(item) =>
						item.id === state.highlightedFlowId ||
						item.localID === state.highlightedFlowId
				);

				if (nodeIndex > -1) {
					state.flows[index].children[nodeIndex] = {
						...state.flows[index].children[nodeIndex],
						...data,
					};
				}
			} catch (e) {
				console.log(e);
			}

			return {
				...state,
			};
		}
		case CONSTANTS.ADD_FLOW: {
			const data = action.payload;

			return {
				...state,
				flows: [
					...state.flows,
					{
						id: data.id,
						label: data.label,
						is_active: false,
						date_created: JSON.stringify(new Date()),
						children: [data],
					},
				],
			};
		}
		case CONSTANTS.ADD_FLOW_CHILD: {
			const index = state.flows.findIndex(
				(item) => item.id === action.payload.id
			);

			try {
				const [mainNode] = state.flows[index].children;
				const id = uniqueId("flow-");
				const flowsLength = state.flows[index].children.length;
				const prevFlowId =
					state.flows[index].children[flowsLength - 1]?.id || mainNode.id;

				state.flows[index].children.push({
					...action.payload,
					id: id,
					starting_flow: mainNode.id,
					true_parent: prevFlowId,
					is_active: true,
					org_node: mainNode.org_node,
				});

				state.highlightedFlowId = id;
			} catch (e) {
				console.log(e);
			}

			return { ...state };
		}
		case CONSTANTS.UPDATE_FLOW_ID: {
			const { flowID, localID, newID } = action.payload;
			const index = state.flows.findIndex(
				(item) => item.id === flowID || item.localID === flowID
			);

			try {
				if (flowID === localID) {
					state.flows[index] = {
						...state.flows[index],
						localID: localID,
						id: newID,
					};
				}

				if (state.flows[index].children?.length > 0) {
					const childIndex = state.flows[index].children.findIndex(
						(item) => item.id === localID
					);

					state.flows[index].children[childIndex].id = newID;
					state.flows[index].children[childIndex].localID = localID;
				}
			} catch (e) {
				console.log(e);
			}

			return { ...state };
		}
		case CONSTANTS.UPDATE_FLOW: {
			const flows = [...state.flows];

			try {
				const index = flows.findIndex((item) => item.id === action.payload.id);
				const data = action.payload.data;

				if (index === -1) {
					if (Object.keys(data).length > 1) flows.push(data);
				} else {
					flows[index] = {
						...state.flows[index],
						...action.payload.data,
					};
				}
			} catch (e) {
				console.log(e);
			}

			return {
				...state,
				flows,
			};
		}
		case CONSTANTS.SET_FLOWS: {
			const dict = {};

			action.payload.forEach((flow) => {
				dict[flow.id] = true;
			});

			const diff = state.flows.filter((flow) => !dict[flow.id]);

			return {
				...state,
				flows: [...action.payload, ...diff],
			};
		}

		case CONSTANTS.REORDER_CHILD: {
			const { flowId, fromIndex, toIndex, callback } = action.payload;
			const index = state.flows.findIndex((item) => item.id === flowId);
			let children = state.flows[index].children;

			try {
				let [child] = children.splice(fromIndex, 1);

				children.splice(toIndex, 0, child);

				state.flows[index].children = children;

				const actualIndex = children.findIndex((item) => item.id === child.id);

				if (typeof callback === "function" && actualIndex > -1)
					callback(children[actualIndex - 1]?.id);
			} catch (e) {
				console.log(e);
			}

			return { ...state };
		}
		case CONSTANTS.DUPLICATE_CHILD: {
			const { flowId, childId } = action.payload;
			const { flows } = state;
			const index = flows.findIndex((item) => item.id === flowId);

			try {
				const child = flows[index].children.find((item) => item.id === childId);

				flows[index].children.push({
					...child,
					id: uniqueId("flow-"),
				});
			} catch (e) {
				console.log(e);
			}

			state.flows = flows;
			state.duplicatedChildId = childId;

			return { ...state };
		}
		case CONSTANTS.DELETE_CHILD: {
			const { flowId, childId } = action.payload;
			const { flows } = state;
			const index = flows.findIndex((item) => item.id === flowId);

			try {
				flows[index].children = flows[index].children.filter(
					(item) => item.id !== childId
				);
			} catch (e) {
				console.log(e);
			}

			state.flows = flows;

			return { ...state };
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default flowsReducer;