import { CONSTANTS } from "./reducer";

export function toggleMenu(isOpen) {
	return {
		type: CONSTANTS.TOGGLE_MENU,
		payload: isOpen,
	};
}

export function toggleSubmenu(label) {
	return {
		type: CONSTANTS.TOGGLE_SUBMENU,
		payload: label,
	};
}
