import { GetUserPermissions } from "Global/functions";

/**
 * only displays children if the current user has one of the provided permissions
 * @param {object} props
 * @param {string | Array<string>} props.permission: String
 * @param {string | null} props.operatorType: String
 * @param {} props.children
 * @returns {?JSX.Element}
 **/
const PermissionContainer = (props) => {
	const userPermissions = GetUserPermissions();
	const { permission, operatorType } = props;

	const anyPermission = () => {
		let hasPermissions = false;

		if (typeof permission === "string" && userPermissions[permission]) {
			hasPermissions = true;
		} else if (typeof permission === "object" && permission.length > 0) {
			for (let i = 0; i < permission.length; i++) {
				if (userPermissions[permission[i]]) {
					hasPermissions = true;
				}
			}
		}

		return hasPermissions;
	};

	const allPermissions = () => {
		let hasPermissions = true;

		if (typeof permission === "string" && !userPermissions[permission]) {
			hasPermissions = false;
		} else if (typeof permission === "object" && permission.length > 0) {
			for (let i = 0; i < permission.length; i++) {
				if (!userPermissions[permission[i]]) hasPermissions = false;
			}
		}

		return hasPermissions;
	};

	const permissionsFuncs = {
		any: anyPermission,
		all: allPermissions,
	};

	let userHasPermission = permissionsFuncs[operatorType || "any"]();

	if (userHasPermission) {
		return props.children;
	} else {
		return null;
	}
};

export default PermissionContainer;
