import { createCookie } from "Global/functions";

export const chooseTree = (tree) => {
	createCookie("tree", JSON.stringify(tree));
	setTimeout(() => {
		window.location.href = "/dashboard";
	}, 300);
};

export const trialEndingDaysLeft = (tree) => {
	if (tree?.date_trial_end) {
		let trialEndingDays = new Date(tree.date_trial_end);
		let timeDifference = trialEndingDays.getTime() - new Date().getTime();

		if (timeDifference <= 0) return 0;
		else {
			return Math.round(timeDifference / (1000 * 3600 * 24));
		}
	} else {
		return null;
	}
};
