const initialState = {
	loading: true,
	hierarchy: [],
	error: null,
};

export const CONSTANTS = {
	TOGGLE_SUBMENU: "TOGGLE_SUBMENU",
	TOGGLE_MENU: "TOGGLE_MENU",
	LOGOUT: "LOGOUT",
};

const menuReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.TOGGLE_MENU: {
			const isOpen =
				"boolean" === typeof action.payload ? action.payload : !state.isOpen;

			return {
				...state,
				isOpen: isOpen,
				activeSubmenu: isOpen ? state.activeSubmenu : null,
			};
		}
		case CONSTANTS.TOGGLE_SUBMENU: {
			return {
				...state,
				isOpen: true,
				activeSubmenu:
					action.payload === state.activeSubmenu ? null : action.payload,
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default menuReducer;