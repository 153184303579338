import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "redux/index";
import InTrialBanner from "features/subscriptions/components/InTrialBanner";
import PastDueBanner from "features/subscriptions/components/PastDueBanner";

const Banner = ({ pastDueStatuses, isStudentOnly, showTrialBanner }) => {
	const { active_tree } = useSelector(({ auth }) => auth);

	if (showTrialBanner) {
		return <InTrialBanner />;
	} else if (
		pastDueStatuses.warning.includes(active_tree.status) &&
		!isStudentOnly
	) {
		return <PastDueBanner />;
	} else {
		return null;
	}
};

Banner.propTypes = {
	pastDueStatuses: PropTypes.object,
	isStudentOnly: PropTypes.bool,
	showTrialBanner: PropTypes.bool,
};

export default Banner;
