import PropTypes from "prop-types";
import { useTranslation } from "utilities/hooks";
import Button from "ComponentLibrary/Button";

const TrialEndedModal = ({ isVisible, setIsVisible }) => {
	const [translate] = useTranslation("subscriptions.messages.trialEnded");

	const handleCloseModal = () => {
		setIsVisible(false);
	};

	if (!isVisible) return null;

	return (
		<div className="trial-ended-modal">
			<div className="trial-ended-message-container">
				<h3>{translate("modalTitle")}</h3>
				<p>{translate("modalLabel1")}</p>
				<p>{translate("modalLabel2")}</p>
				<Button
					className="mt-4 ml-auto"
					label={translate("modalButton")}
					onClick={handleCloseModal}
					size="small"
				/>
			</div>
		</div>
	);
};

TrialEndedModal.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	setIsVisible: PropTypes.func.isRequired,
};

export default TrialEndedModal;
