import { CONSTANTS } from "./reducer";

export function removeEmailTemplate(emailTemplateID) {
	return {
		type: CONSTANTS.REMOVE_EMAIL_TEMPLATE,
		payload: emailTemplateID,
	};
}

export function setEmailTemplates(emailTemplates) {
	return {
		type: CONSTANTS.SET_EMAIL_TEMPLATES,
		payload: emailTemplates,
	};
}

export function addEmailTemplate(emailTemplate) {
	return {
		type: CONSTANTS.ADD_EMAIL_TEMPLATE,
		payload: emailTemplate,
	};
}
