import React from "react";
import PropTypes from "prop-types";
import { useReduxDevtools } from "redux/DevToolsProvider";

let activeName = null;
if (typeof window.skillsuite !== "object") window.skillsuite = {};

const forEachReducer = (reducers, initialState, action) => {
	const keys = Object.keys(reducers);
	const state = { ...initialState };

	keys.forEach((key) => {
		const reducer = reducers[key];

		state[key] = reducer(state[key], action);
	});

	return state;
};

export const Context = React.createContext([null]);

export const combineReducers = (reducers) => {
	return {
		state: forEachReducer(reducers, undefined, {}),
		reducers: (state, action) => forEachReducer(reducers, state, action),
	};
};

export const Provider = ({ store, name, children }) => {
	const [initial, setInitial] = React.useState(true);
	const [state, setState] = React.useState(store?.state);
	const [reducerState, dispatch] = React.useReducer(
		store?.reducers,
		store?.state
	);

	activeName = name;
	window.skillsuite.redux = {
		...window.skillsuite.redux,
		[name]: { state, dispatch },
	};

	React.useEffect(() => {
		if (
			JSON.stringify(state) !== JSON.stringify(reducerState) &&
			!!reducerState
		) {
			setState(reducerState);
			localStorage.setItem("redux-state", JSON.stringify(reducerState));
		}
	}, [state, reducerState]);

	React.useEffect(() => {
		const localState = JSON.parse(localStorage.getItem("redux-state"));
		const isDifferent = JSON.stringify(localState) !== JSON.stringify(state);

		if (initial === true) {
			setInitial(false);

			if (isDifferent && !!localState) setState(localState);
		}
	}, [initial, setInitial, setState, state]);

	return (
		<Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
	);
};

Provider.propTypes = {
	name: PropTypes.string,
	store: PropTypes.object,
	children: PropTypes.any,
};

export const useSelector = (callback) => {
	const { state } = React.useContext(Context);

	try {
		return callback(state);
	} catch (e) {
		return undefined;
	}
};

export const dispatch = (data) => {
	const isFunction = typeof data === "function";

	if (!activeName || !window?.skillsuite?.redux[activeName]) {
		if (isFunction) return setTimeout(() => dispatch(data), 1000);

		return null;
	}

	const { state, dispatch: mainDispatch } =
		window?.skillsuite?.redux[activeName];

	try {
		if (isFunction) return data(dispatch, state);

		if (typeof data === "object") return mainDispatch(data);
	} catch (e) {
		console.log(e);
		return false;
	}
};

export const useDispatch = () => {
	try {
		const devToolsDispatch = useReduxDevtools();

		return (data) => {
			devToolsDispatch(data);
			dispatch(data);
		};
	} catch (e) {
		return function () {};
	}
};

export const connect = (mapStateToProps) => {
	// eslint-disable-next-line
	return (Component) => {
		// eslint-disable-next-line
		return (props) => {
			const updatedProps = {
				...props,
				...mapStateToProps(props),
			};

			return <Component {...updatedProps} />;
		};
	};
};
