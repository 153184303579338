import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { useSelector } from "redux/index";
import { useTranslation } from "utilities/hooks";
import { useHistory } from "react-router-dom";
import { isShopify } from "settings";
import { AsyncGet } from "Global/api";
import { trialEndingDaysLeft } from "./utils";
import Header from "components/Header";
import Banner from "oldDirectory/components/objects/Banner";
import TreeCanceled from "./components/TreeCanceled";
import TrialEndedModal from "./components/TrialEndedModal";
import "./subscriptions.scss";

const SubscriptionValidation = ({ children }) => {
	const [isTrialEndedModalVisible, setIsTrialEndedModalVisible] =
		useState(false);
	const [isFetchingURL, setIsFetchingURL] = useState(false);
	const { is_student_only, active_tree, userData } = useSelector(
		({ auth }) => auth
	);
	const [permissions, permissionLoading] = useSelector(({ permissions }) => [
		permissions.permissions,
		permissions.loading,
	]);
	const trialDaysLeft = trialEndingDaysLeft(active_tree);
	const history = useHistory();
	const [translate] = useTranslation("subscriptions.shopify.pending-alert");

	const pastDueStatuses = {
		warning: [
			"trial_ended",
			"past_due_0",
			"past_due_1",
			"past_due_2",
			"past_due_3",
		],
		pending: ["pending"],
		blocking: ["trial_ended", "past_due_2", "past_due_3"],
		terminated: ["canceled", "past_due_4"],
	};

	const showTrialBanner =
		!is_student_only &&
		active_tree.is_in_trial &&
		trialDaysLeft &&
		trialDaysLeft > 0 &&
		!active_tree.card_added &&
		active_tree.plan?.provider === "stripe";

	const bannerShowing =
		showTrialBanner || pastDueStatuses.warning.includes(active_tree.status);

	const isBlocking = pastDueStatuses.blocking.includes(active_tree.status);
	const isPending = pastDueStatuses.pending.includes(active_tree.status);
	const isTerminated = pastDueStatuses.terminated.includes(active_tree.status);
	const isAdmin = userData?.user_roles?.[0]?.role === "admin";
	const isAtBillingRoute = history.location?.search.includes("trial_ended");
	const isAtUsersRoute = history.location?.pathname.includes("/users");
	const isAtEnrollmentsRoute =
		history.location.pathname.includes("/my-courses");

	const shouldCheckBillingAccessPermission =
		isBlocking &&
		isAdmin &&
		!isAtBillingRoute &&
		!isAtUsersRoute &&
		!isAtEnrollmentsRoute &&
		!permissionLoading;

	const openShopifyModal = async () => {
		try {
			setIsFetchingURL(true);

			const {
				data: { billing },
			} = await AsyncGet("/v2/billing/subscription/" + active_tree.id);

			const { confirmation_url } = billing.pop();

			if (typeof confirmation_url !== "string" && confirmation_url?.length < 1)
				return false;

			const result = await Swal.fire({
				title: translate("title"),
				text: translate("text"),
				confirmButtonText: translate("confirm"),
				cancelButtonText: translate("cancel"),
				showCancelButton: true,
				allowOutsideClick: () => false,
			});

			if (result.isConfirmed) {
				window.location.href = confirmation_url;
			} else {
				window.location.href = "/logout";
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		(async function () {
			if (isPending && isShopify() && isFetchingURL === false)
				return await openShopifyModal();

			if (shouldCheckBillingAccessPermission === false) return false;

			if (permissions?.billing_access)
				return history.push("/settings/billing/?trial_ended");

			setIsTrialEndedModalVisible(true);
		})();
	}, [
		shouldCheckBillingAccessPermission,
		openShopifyModal,
		isFetchingURL,
		permissions,
		isPending,
		history,
	]);

	if (active_tree.status || Object.keys(active_tree).length === 0) {
		if (isTerminated) {
			return <TreeCanceled />;
		} else {
			return (
				<>
					<Banner
						pastDueStatuses={pastDueStatuses}
						isStudentOnly={is_student_only}
						showTrialBanner={showTrialBanner}
					/>
					<Header
						pastDueStatuses={pastDueStatuses}
						bannerShowing={bannerShowing}
					/>
					{!permissions?.billing_access &&
						typeof permissions?.billing_access !== "undefined" && (
							<TrialEndedModal
								isVisible={isTrialEndedModalVisible}
								setIsVisible={setIsTrialEndedModalVisible}
							/>
						)}
					{children}
				</>
			);
		}
	} else {
		return null;
	}
};

SubscriptionValidation.propTypes = {
	children: PropTypes.element,
};

export default SubscriptionValidation;
