import { useIntl } from "react-intl";

export const useTranslation = (str1 = "") => {
	const intl = useIntl();

	const t = (str2, values = {}) => {
		const id = (str1.length > 0) ? str1 + "." + str2 : str2;

		return intl.formatMessage({ id }, {
			b: function applyBold(...chunks) {
				return <b>{chunks}</b>;
			},
			...values,
		});
	}

	return [t];
};
