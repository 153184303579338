import jwt from "jsonwebtoken";
import axios from "axios";
import { readCookie } from "Global/functions";
import { CONSTANTS } from "./reducer";
import * as R from "ramda";

const fetchUserInfo = (userId, treeId) => async (dispatch) => {
	if (userId && treeId) {
		try {
			dispatch({ type: CONSTANTS.USER_INFO_REQUEST });

			const user = await axios.get(
				`v2/user/header/${userId}/${treeId}/?in_tree=${treeId}`
			);

			const combinedUserResponse = {
				...user.data,
				user_roles: user.data.user_roles,
			};

			dispatch({
				type: CONSTANTS.USER_INFO_SUCCESS,
				payload: combinedUserResponse,
			});
			dispatch({
				type: CONSTANTS.USER_ASSOCIATED_TREES,
				payload: user.data.associated_trees,
			});

			sessionStorage.setItem("userData", JSON.stringify(combinedUserResponse));
		} catch (err) {
			console.error(err);
			dispatch({ type: CONSTANTS.USER_INFO_CATCH, payload: err.response });
		}
	}
};

export const fetchBillingInfo = (treeId) => async (dispatch) => {
	sessionStorage.removeItem("pending_billing_profile_url");

	if (treeId) {
		try {
			const billingResponse = await axios.get(
				`/v2/billing/subscription/${treeId}/`
			);

			let pendingBillingProfile = R.find(
				R.both(R.propEq("status", "pending"), R.propEq("type", "shopify")),
				billingResponse.data.billing
			);

			if (pendingBillingProfile)
				sessionStorage.setItem(
					"pending_billing_profile_url",
					pendingBillingProfile.confirmation_url
				);

			dispatch({
				type: CONSTANTS.BILLING_INFO_SUCCESS,
				payload: {
					date_trial_end: billingResponse.data.date_trial_end,
					billable_users_count: billingResponse.data.billable_users_count,
					status: billingResponse.data.status,
					is_in_trial: billingResponse.data.is_in_trial,
					card_added: billingResponse.data.card_added,
					plan: billingResponse.data.plan,
					billing_profile: billingResponse.data.billing,
				},
			});
		} catch (err) {
			console.error(err);
			dispatch({ type: CONSTANTS.BILLING_INFO_CATCH, payload: err.response });
		}
	}
};

export const decodeUser = () => (dispatch) => {
	try {
		const decodedUser = jwt.decode(readCookie("jwtRefresh"));
		const tree = JSON.parse(readCookie("tree"));
		dispatch(fetchUserInfo(decodedUser.user_id, tree.id));
		dispatch(fetchBillingInfo(tree.id));
	} catch (e) {
		window.location.href = "/logout";
	}
};

export const fetchAccountDetails = (treeId, callback) => async (dispatch) => {
	try {
		const { data } = await axios.get(`/v2/organization/tree/?id=${treeId}`);
		const treeData = data.results?.[0];

		if (!treeData) return false;

		dispatch({ type: CONSTANTS.USER_ACTIVE_TREE, payload: treeData });

		if (typeof callback === "function") callback(treeData);
	} catch (e) {
		console.log(e);
	}
};

export const logoutUser = () => (dispatch) => {
	dispatch({ type: CONSTANTS.LOGOUT });
};
