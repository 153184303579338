/** @format */

import axios from "axios";
import { accessTokenValidity } from "./authenticated";
import _ from "lodash";

/**
 * @param {string} url
 * @param {Object} data
 * @param {function(Object)} func
 * @param {AxiosRequestConfig} [config]
 * @param {boolean=false} showError
 */
export const Post = (url, data, func, config, showError = false) => {
	const axiosCall = () => {
		axios
			.post(url, data, config)
			.then((res) => {
				try {
					func(res);
				} catch (err) {
					console.error(err);
				}
			})
			.catch((err) => {
				if (showError === false) {
					func(err);
				}
			});
	};
	accessTokenValidity(axiosCall);
};

/**
 * @param {string} url
 * @param {Object} data
 * @param {function(Object)} func
 * @param {AxiosRequestConfig} [config]
 * @param {boolean=false} showError
 */
export const Put = (url, data, func, config, showError = false) => {
	const axiosCall = () => {
		axios
			.put(url, data, config)
			.then((res) => {
				try {
					func(res);
				} catch (err) {
					console.error(err);
				}
			})
			.catch((err) => {
				if (showError === false) {
					func(err);
				}
			});
	};

	accessTokenValidity(axiosCall);
};

/**
 * @param {string} url
 * @param {Object} data
 * @param {function(Object)} func
 * @param {AxiosRequestConfig} [config]
 * @param {boolean=false} showError
 */
export const Patch = (url, data, func, config, showError = false) => {
	const axiosCall = () => {
		axios
			.patch(url, data, config)
			.then((res) => {
				try {
					func(res);
				} catch (err) {
					console.error(err);
				}
			})
			.catch((err) => {
				if (showError === false) {
					func(err);
				}
			});
	};

	accessTokenValidity(axiosCall);
};

/**
 * @param {string} url
 * @param {function(Object)} func
 * @param {boolean=false} showError
 */
export const Delete = (url, func, showError = false) => {
	const axiosCall = () => {
		axios
			.delete(url)
			.then((res) => {
				try {
					func(res);
				} catch (err) {
					console.error(err);
				}
			})
			.catch((err) => {
				if (showError === false) {
					func(err);
				}
			});
	};

	accessTokenValidity(axiosCall);
};

/**
 * @param {string} url
 * @param {function(Object)} func
 * @param {boolean=false} showError
 */
export const Get = (url, func, showError = false) => {
	const axiosCall = () => {
		axios
			.get(url)
			.then((res) => {
				try {
					func(res);
				} catch (err) {
					console.error(err);
				}
			})
			.catch((err) => {
				if (showError === false) {
					func(err);
				}
			});
	};
	accessTokenValidity(axiosCall);
};

/**
 * @param {string} url
 * @param {boolean = false} showError
 * @returns {Promise<unknown>}
 */
export const AwaitGet = (url, showError) => {
	if (accessTokenValidity()) {
		return new Promise(function (resolve) {
			AsyncGet(url)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					if (showError !== false) {
						resolve(err);
					}
				});
		});
	}
};

/**
 * @param {{url: string, data: Object}[]} requests
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<*>>}
 */
export const AsyncPatchAll = async (requests, config) => {
	if (accessTokenValidity()) {
		return Promise.all(
			_.map(requests, ({ url, data }) => axios.patch(url, data, config))
		);
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {{url: string, data: Object}[]} requests
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<*>>}
 */
export const AsyncPostAll = async (requests, config) => {
	if (accessTokenValidity()) {
		return Promise.all(
			_.map(requests, ({ url, data }) => axios.post(url, data, config))
		);
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {string[]} urls
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<*>>[]>}
 */
export const AsyncGetAll = (urls, config) => {
	if (accessTokenValidity()) {
		return Promise.all(urls.map((url) => AsyncGet(url, 1, config)));
	}
	return Promise.reject("invalid access token");
};
/**
 * @param {string[]} urls
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<*>>[]>}
 */
export const AsyncDeleteAll = (urls, config) => {
	console.log(urls);
	if (accessTokenValidity()) {
		return Promise.all(urls.map((url) => axios.delete(url, config)));
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {string} url
 * @param {number} [n=1] - number of times to retry the request
 * @param {Object | URLSearchParams} [params={}]
 * @throws {Error}
 * @returns {Promise<AxiosResponse<*>>}
 */
export const AsyncGet = async (url, n = 1, params = {}) => {
	if (accessTokenValidity()) {
		try {
			return await axios.get(url, { params });
		} catch (error) {
			if (n <= 1) {
				return Promise.reject(error);
			}
			return await AsyncGet(url, n - 1, params);
		}
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {string} url
 * @param {Object} data
 * @param {AxiosRequestConfig | {}} [config={}]
 * @returns {Promise<AxiosResponse<*>>}
 */
export const AsyncPost = async (url, data, config = {}) => {
	if (accessTokenValidity()) {
		return await axios.post(url, data, config);
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {string} url
 * @param {Object} data
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<*>>}
 */
export const AsyncPatch = async (url, data, config) => {
	if (accessTokenValidity()) {
		return await axios.patch(url, data, config);
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {string} url
 * @param {Object} data
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const AsyncPut = async (url, data, config) => {
	if (accessTokenValidity()) {
		return await axios.put(url, data, config);
	}
	return Promise.reject("invalid access token");
};

/**
 * @param {string} url
 * @param {AxiosRequestConfig} [config]
 * @returns {Promise<AxiosResponse<*>>}
 */
export const AsyncDelete = async (url, config) => {
	if (accessTokenValidity()) {
		return await axios.delete(url, config);
	}
	return Promise.reject("invalid access token");
};

// WEB SOCKETS!

export const openSocket = (url) => {
	const wsStart = process.env.REACT_APP_SOCKET_URL + url;
	document.cookie = "csrftoken=erUoqLFeUXTjg4Lcb4COR1AAWBkcybzfqjDlBJWLgM529vqSZIkBy2cjeMPCNEEW";
	return new WebSocket(wsStart);
};
