import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

const DevToolsContext = React.createContext();

export const useReduxDevtools = () => {
	return useContext(DevToolsContext);
};

function useInternalReduxDevtools() {
	// eslint-disable-next-line no-underscore-dangle
	const withDevTools =
		typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__;
	let devTools = { send: () => {} };

	useEffect(() => {
		if (withDevTools) {
			// eslint-disable-next-line no-underscore-dangle
			devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect();
			devTools.init();

			return () => {
				// eslint-disable-next-line no-underscore-dangle
				window.__REDUX_DEVTOOLS_EXTENSION__.disconnect();
			};
		}

		return () => {};
	});

	return (data) => {
		if (typeof data === "function") {
			return devTools.send("FUNCTION", {});
		}
		return devTools.send(data.type, data.payload);
	};
}

const DevToolsProvider = ({ children }) => {
	const tool = useInternalReduxDevtools();

	return (
		<DevToolsContext.Provider value={tool}>{children}</DevToolsContext.Provider>
	);
};

DevToolsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DevToolsProvider;
