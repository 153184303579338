import { combineReducers } from "redux/index";

import permissions from "./permissions/reducer";
import hierarchy from "./hierarchy/reducer";
import courses from "./courses/reducer";
import general from "./general/reducer";
import emails from "./emails/reducer";
import users from "./users/reducer";
import flows from "./flows/reducer";
import auth from "./auth/reducer";
import menu from "./menu/reducer";

export const store = combineReducers({
	permissions,
	hierarchy,
	courses,
	general,
	emails,
	users,
	flows,
	auth,
	menu
});
