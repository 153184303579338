const PROVIDERS = {
	STRIPE: "stripe",
	SHOPIFY: "shopify",
};

export const PAYMENT = {
	PROVIDERS,
};

export function isShopify() {
	const provider =
		window.skillsuite?.redux?.skillsuite_platform?.state?.auth?.active_tree
			?.plan?.provider;

	return PROVIDERS.SHOPIFY === provider;
}

export function isStripe() {
	const provider =
		window.skillsuite?.redux?.skillsuite_platform?.state?.auth?.active_tree
			?.plan?.provider;

	return PROVIDERS.STRIPE === provider;
}
