import { CONSTANTS } from "./reducer";

export function removeUser(userID) {
	return {
		type: CONSTANTS.REMOVE_USER,
		payload: userID,
	};
}

export function setUsers(users) {
	return {
		type: CONSTANTS.SET_USERS,
		payload: users,
	};
}

export function setUsersLoading(value) {
	return {
		type: CONSTANTS.SET_USERS_LOADING,
		payload: value,
	};
}

export function addUser(user) {
	return {
		type: CONSTANTS.ADD_USER,
		payload: user,
	};
}
