const initialState = {
	users: [],
	isLoadingUsers: false,
};

export const CONSTANTS = {
	SET_USERS_LOADING: "SET_USERS_LOADING",
	REMOVE_USER: "REMOVE_USER",
	SET_USERS: "SET_USERS",
	ADD_USER: "ADD_USER",
	LOGOUT: "LOGOUT",
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.REMOVE_USER: {
			return {
				...state,
				users: state.users.filter((user) => user.id !== action.payload),
			};
		}
		case CONSTANTS.SET_USERS: {
			return {
				...state,
				users: action.payload,
			};
		}
		case CONSTANTS.SET_USERS_LOADING: {
			return {
				...state,
				isLoadingUsers: action.payload,
			};
		}
		case CONSTANTS.ADD_USER: {
			return {
				...state,
				users: [...state.users, action.payload],
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default userReducer