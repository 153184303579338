const initialState = {
	loading: true,
	hierarchy: [],
	error: null,
};

export const CONSTANTS = {
	HIERARCHY_SUCCESS: "HIERARCHY_SUCCESS",
	HIERARCHY_REQUEST: "HIERARCHY_REQUEST",
	HIERARCHY_CATCH: "HIERARCHY_CATCH",
	LOGOUT: "LOGOUT",
};

const hierarchyReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.HIERARCHY_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case CONSTANTS.HIERARCHY_SUCCESS: {
			return {
				...state,
				hierarchy: action.payload,
				loading: false,
			};
		}
		case CONSTANTS.HIERARCHY_CATCH: {
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default hierarchyReducer;