import { useSelector } from "redux/index";
import { trialEndingDaysLeft } from "features/subscriptions/utils";
import { showCalendly } from "Global/functions";

const InTrialBanner = () => {
	const { permissions } = useSelector(({ permissions }) => permissions);
	const { active_tree } = useSelector(({ auth }) => auth);
	const trialDaysLeft = trialEndingDaysLeft(active_tree);

	return (
		<div className="div-container" id="bannerContainer">
			<div className="banner-box">
				<div className="banner">
					<strong>Trial remaining: {trialDaysLeft} days.</strong>
					{permissions.billing_access ? (
						<div className="banner-buttons">
							<button
								className="btn btn-small btn-banner-sales mt-0 mb-0"
								type="button"
								onClick={showCalendly}>
								Speak to sales
							</button>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default InTrialBanner;
