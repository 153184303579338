/** @format */
import error404 from "assets/images/error404.jpg";

const PageNotFound = () => {
	return (
		<div>
			<img
				className="col-12 col-md-6 p-0 position-absolute errorImg"
				src={error404}
				alt="404"
			/>
			<div className="errorMsg position-absolute ">
				<div className="d-flex flex-column">
					<h2 className="font-weight-bold display-4">404</h2>
					<h4 className="text-capitalize mt-2">
						We cannot find the page you are looking for.
					</h4>
					<a className="btn btn-regular w-50  ml-0 mt-4" href="/">
						Back to home
					</a>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
