export const CONSTANTS = {
	UPDATE_PLANS: "UPDATE_PLANS",
	LOGOUT: "LOGOUT",
};

const initialState = {
	plans: [],
};

const generalReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.UPDATE_PLANS: {
			return {
				...state,
				plans: action.payload,
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default generalReducer;