import { CONSTANTS } from "./reducer";
import { AsyncGet } from "Global/api";

export const fetchPermissions = () => async (dispatch, state) => {
	const tree_id = state?.auth?.active_tree?.id || null;

	try {
		dispatch({ type: CONSTANTS.PERMISSIONS_REQUEST });

		const res = await AsyncGet("/user/my-permission/", 2, {
			in_tree: tree_id,
		});

		dispatch({ type: CONSTANTS.PERMISSIONS_SUCCESS, payload: res.data });
	} catch (err) {
		console.error(err);
		dispatch({ type: CONSTANTS.PERMISSIONS_CATCH, payload: err });
	}
};
