import axios from "axios";
import history from "Global/history";
import * as R from "ramda";
import Swal from "sweetalert2";
import { pdfjs } from "react-pdf";

import setAuthorizationToken from "oldDirectory/components/account/setAuthorizationToken";

import {
	createCookie,
	readCookie,
	errorToast,
	successToast,
	eraseCookie,
} from "./functions";
import _ from "lodash";

// Make axios send all requests relative to the host
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

// Make sure that the params field is set on all requests, so interceptors can rely on it existing.
axios.defaults.params = {};

// Interceptor to force a log out and redirect to home page
// TODO: queue failed requests for retry upon token refresh
// https://gist.github.com/yajra/5f5551649b20c8f668aec48549ef5c1f
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		const originalRequest = error.config;

		const shouldRetry = R.both(
			R.pathEq(["response", "status"], 401),
			R.either(
				R.pathEq(["response", "data", "code"], "token_not_valid"),
				R.pathEq(
					["response", "data", "error"],
					"Given token not valid for any token type"
				)
			)
		);

		// 400 <= error.response.status < 500 && !== (401 | 403 | 404)
		const isReportableError = R.pathSatisfies(
			R.allPass([
				R.lte(400),
				R.gt(500),
				R.complement(R.includes(R.__, [401, 403, 404])),
			]),
			["error", "response", "status"]
		);

		if (shouldRetry(error)) {
			originalRequest._retry = true;
			return axios
				.post("/user/api/token/refresh/", {
					refresh: readCookie("jwtRefresh"),
				})
				.then(({ data }) => {
					setAuthorizationToken(data.access);
					createCookie("jwtAccess", data.access);
					originalRequest.headers["Authorization"] = "Bearer " + data.access;
					return axios(originalRequest);
				})
				.catch(() => {
					eraseCookie("jwtRefresh");
					eraseCookie("jwtAccess");
					eraseCookie("accounts");
					eraseCookie("tree");
					sessionStorage.clear();
					window.location.href = "/account/login/";
				});
		} else if (isReportableError(error)) {
			return Swal.fire({
				confirmButtonText: "Yes, Report!",
				icon: "error",
				position: "bottom-end",
				text: "Report this error?",
				timer: 5000,
				timerProgressBar: true,
				titleText: "Oops!",
				toast: true,
				customClass: { container: "swal-email-admin" },
				didOpen: (toast) => {
					toast.addEventListener("mouseenter", Swal.stopTimer);
					toast.addEventListener("mouseleave", Swal.resumeTimer);
				},
			}).then(({ value }) => {
				if (true === value) {
					const userData = JSON.parse(
						R.defaultTo({}, sessionStorage.getItem("userData"))
					);

					const userObject = R.props(
						["id", "email", "first_name", "last_name"],
						userData
					);
					const errorObject = R.props(
						["config", "response", "status", "statusText"],
						error
					);

					axios
						.post("/notification/email-admin/", {
							body: JSON.stringify({
								...errorObject,
								frontend_url: window.location.href,
								user: userObject,
							}),
							subject: `Skillsuite ${error.response.status} Error`,
						})
						.then(() =>
							successToast("Success!", "Error reported successfully!")
						)
						.catch(() =>
							errorToast(
								"Error!",
								"There was an issue submitting your error, please contact customer service for support."
							)
						);
				}
			});
		}
		return Promise.reject(error);
	}
);

/**
 * @type {function}
 * @description interceptor to append the in_tree filter to every request against the backend
 * @param {number | string} treeId
 * @param {string} backendUrl
 * @param {AxiosRequestConfig} config
 */
export const interceptorAppendInTree = R.curry((treeId, backendUrl, config) => {
	return R.ifElse(
		R.where({
			baseURL: R.equals(backendUrl),
			url: R.complement(R.includes("/user/api/token")),
			method: R.either(R.includes("get"), R.includes("GET")),
		}),
		R.evolve({
			params: R.assoc("in_tree", treeId),
		}),
		R.identity
	)(config);
});

// Set the worker globally
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
