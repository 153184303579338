import { readCookie, createCookie } from "Global/functions";

const treeCookie = readCookie("tree");
const associatedTreeCookie = readCookie("accounts");
const userDataCookie = sessionStorage.getItem("userData");

const initialState = {
	loading: false,
	userData: JSON.parse(
		userDataCookie && userDataCookie !== "undefined"
			? sessionStorage.getItem("userData")
			: "{}"
	),
	active_tree: JSON.parse(
		treeCookie && treeCookie !== "undefined" ? treeCookie : "{}"
	),
	associated_trees: JSON.parse(
		associatedTreeCookie && associatedTreeCookie !== "undefined"
			? associatedTreeCookie
			: "[]"
	),
	isAuthenticated: false,
	is_student_only: null,
	error: {},
};

export const CONSTANTS = {
	USER_ASSOCIATED_TREES: "USER_ASSOCIATED_TREES",
	USER_ACTIVE_TREE: "USER_ACTIVE_TREE",

	BILLING_INFO_SUCCESS: "BILLING_INFO_SUCCESS",
	BILLING_INFO_CATCH: "BILLING_INFO_CATCH",

	USER_INFO_REQUEST: "USER_INFO_REQUEST",
	USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
	USER_INFO_CATCH: "USER_INFO_CATCH",

	LOGOUT: "LOGOUT",
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.BILLING_INFO_SUCCESS: {
			let active_tree = {
				...state.active_tree,
				...action.payload,
			};

			createCookie("tree", JSON.stringify(active_tree));

			return {
				...state,
				active_tree: active_tree,
			};
		}
		case CONSTANTS.BILLING_INFO_CATCH: {
			return {
				...state,
				error: {
					message: action?.payload?.data?.error || "Something went wrong!",
					status: action?.payload?.status || "400",
				},
			};
		}

		case CONSTANTS.USER_ASSOCIATED_TREES: {
			let { active_tree } = state;
			const updatedActiveTree = action.payload.find(
				(tree) => tree.id === state.active_tree.id
			);

			if (active_tree && updatedActiveTree) {
				active_tree = {
					...active_tree,
					...updatedActiveTree,
				};

				createCookie("tree", JSON.stringify(active_tree));
			}

			return {
				...state,
				active_tree,
				associated_trees: action.payload,
			};
		}
		case CONSTANTS.USER_ACTIVE_TREE: {
			let active_tree = {
				...state.active_tree,
				...action.payload,
			};

			createCookie("tree", JSON.stringify(active_tree));

			return {
				...state,
				active_tree: active_tree,
			};
		}

		case CONSTANTS.USER_INFO_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case CONSTANTS.USER_INFO_SUCCESS: {
			return {
				...state,
				userData: action.payload,
				isAuthenticated: action?.payload?.is_active,
				is_student_only: action?.payload?.user_roles[0].is_student_only,
				loading: false,
			};
		}
		case CONSTANTS.USER_INFO_CATCH: {
			return {
				...state,
				error: {
					message: action?.payload?.data?.error || "Something went wrong!",
					status: action?.payload?.status || "400",
				},
				loading: false,
				isAuthenticated: false,
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default authReducer;