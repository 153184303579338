import { useEffect, useCallback, useState } from "react";
import { parseUrl, stringify } from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

/**
 * Hook used to manage query string parameters in a url.
 * @todo deprecate in favor of React-Router v6's hook
 * @param {string} initialUrl
 * @param {Object<string, (number | string | undefined | null)>}initialParams
 **/
export const useQueryParams = (initialUrl, initialParams) => {
	const location = useLocation();
	const history = useHistory();
	const [queryParams, _setQueryParams] = useState(() => ({
		...initialParams,
		..._.get(
			parseUrl(initialUrl, { parseBooleans: true, parseNumber: true }),
			"query",
			{}
		),
	}));

	// On changes to queryParams, update the url to match
	useEffect(() => {
		let didCancel = false;
		if (!didCancel) {
			history.replace({
				pathname: location.pathname,
				search: stringify(_.omit(queryParams, "page_size"), {
					skipEmptyString: true,
					skipNull: true,
				}),
			});
		}
		return () => {
			didCancel = true;
		};
	}, [history, location.pathname, queryParams]);

	/**
	 * @param newParams - an object to be merged into the current querystring
	 * Note: to remove a value from the current query string, pass it as an empty string in queryParams
	 * E.g: queryString = { a: 'a', b: 'b'} yields '?a=a&b=b',
	 * to remove the 'b' parameter, pass {b: ''} to this function
	 */
	const setQueryParams = useCallback(
		(newParams = {}) => {
			_setQueryParams((prev) => ({ ...prev, ...newParams }));
		},
		[_setQueryParams]
	);

	return [queryParams, setQueryParams];
};
