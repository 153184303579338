/** @format */

import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "redux/index";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import { fetchAccountDetails } from "store/auth/actions";
import { useTranslation } from "utilities/hooks";

import { toggleMenu } from "store/menu/actions";
import AdminLink from "oldDirectory/components/objects/AdminLink";
import PermissionContainer from "oldDirectory/containers/PermissionContainer";
import { logoutUser } from "Global/functions";

import themeColors from "assets/css/js-exports/_colors.module.scss";
import { ReactComponent as Hamburger } from "assets/images/icons/hamburger2.svg";
import { ReactComponent as BackIcon } from "assets/images/navigation/arrow-back.svg";
import { ReactComponent as ForwardIcon } from "assets/images/navigation/arrow-forward.svg";

import "./styles.scss";

const logoDefault =
	"https://live-skillsuite.sfo2.digitaloceanspaces.com/live-skillsuite/media/org_node_logo/skillSuite_logo.jpg";

const Header = ({ pastDueStatuses, bannerShowing }) => {
	const [translate] = useTranslation("header");
	const hierarchy = useSelector(({ hierarchy }) => hierarchy);

	const {
		associated_trees,
		active_tree,
		userData,
		isAuthenticated,
		is_student_only,
	} = useSelector(({ auth }) => auth);
	const menuIsOpen = useSelector(({ menu }) => menu.isOpen);
	const { first_name, last_name, email } = userData;

	const dispatch = useDispatch();
	const blackListMatch = useRouteMatch({
		path: ["/account/", "/my-courses/:id/", "/tracks/preview/"],
	});
	const [showAccountMenu, setShowAccountMenu] = useState(false);
	const [showSettings, setShowSettings] = useState(false);

	const userInitials = useMemo(() => {
		if (typeof email !== "string")
			return "";

		if (typeof first_name !== "string" || first_name.length === 0)
			return email.charAt(0).toUpperCase();

		return (first_name.charAt(0) + last_name.charAt(0)).toUpperCase();
	}, [first_name, last_name, email]);

	const logo = useMemo(
		() =>
			!_.isEmpty(_.get(hierarchy, "hierarchy[0].logo_file", null))
				? _.get(hierarchy, "hierarchy[0].logo_file", null)
				: logoDefault,
		[hierarchy]
	);
	const accountPortrait = useMemo(
		() =>
			userData.profile_picture ? (
				<img
					src={userData.profile_picture}
					alt={translate("profile_picture")}
				/>
			) : (
				userInitials
			),
		[userData, userInitials, translate]
	);

	// Handles cookie change if they select a different tree
	const handleAccountSelect = (tree) => {
		const callback = (data) => {
			window.location.href = "/dashboard";
		};

		dispatch(fetchAccountDetails(tree.id, callback));
	};

	if (blackListMatch !== null || (isAuthenticated === false && Object.keys(active_tree).length > 0))
		return null;

	return (
		<header
			id="headerarea"
			className={`headerarea ${bannerShowing ? "push-down" : ""}`.trim()}>
			<div className="logo-container">
				<nav className="header-nav">
					<Link
						to={
							is_student_only ||
							pastDueStatuses.blocking.includes(active_tree.status)
								? "/my-courses/"
								: "/dashboard/"
						}
						className="logo">
						<img src={logo} alt="Company logo" />
					</Link>

					{is_student_only === false && (
						<>
							<AdminLink to="/dashboard/" className="logo-titles">
								<h2>{translate("options.dashboard")}</h2>
							</AdminLink>
							<PermissionContainer permission={["view_user", "view_orgnode"]}>
								<AdminLink to="/organization/" className="logo-titles">
									<h2>{translate("options.users_and_groups")}</h2>
								</AdminLink>
							</PermissionContainer>

							{!pastDueStatuses.blocking.includes(active_tree.status) && (
								<>
									<AdminLink to="/tracks/" className="logo-titles">
										<h2>{translate("options.courses")}</h2>
									</AdminLink>
									<AdminLink to="/flows/" className="logo-titles">
										<h2>{translate("options.flows")}</h2>
									</AdminLink>
								</>
							)}
						</>
					)}

					<NavLink to="/my-courses/" className="logo-titles">
						<h2>{translate("options.my_enrollments")}</h2>
					</NavLink>
				</nav>
				<nav
					className={`hamburger-navbar navbar-dark ${
						menuIsOpen ? "active" : ""
					}`.trim()}>
					<Hamburger
						onClick={() => {
							dispatch(toggleMenu());
							setShowSettings(false);
						}}
						width="30"
						height="100%"
						className="hamburger"
					/>
				</nav>
			</div>
			<div
				className={`mobile-navigation${menuIsOpen ? " active" : ""}`.trim()}>
				<div className="inner-mobile-navigation">
					<div className="mobile-account-box">
						<div className="d-flex align-items-center mb-2 mt-2">
							<Link className="account-name" to="/settings/profile/">
								{accountPortrait ? accountPortrait : userInitials}
							</Link>
							<div>
								<h4 className="m-0 d-flex align-items-center">
									{_.get(userData, "first_name", "")}
								</h4>
								<h6 className="m-0 d-flex align-items-center">
									{active_tree.label || active_tree.subdomain || ""}
								</h6>
							</div>
						</div>
						{associated_trees.length > 1 ? (
							<div className="mobile-account-links small-scrollbar pb-4">
								<h3>{translate("menu.switch_accounts")}</h3>

								{_.map(associated_trees, (tree, i) => {
									if (tree.id !== active_tree.id) {
										return (
											<div
												key={`${_.get(
													tree,
													"subdomain",
													"subdomain"
												)}-${i}`.trim()}
												className="mobile-account"
												onClick={() => handleAccountSelect(tree)}>
												<div className="d-flex flex-column">
													<p className="account-username">
														{tree.label || tree.subdomain || ""}
													</p>
												</div>
											</div>
										);
									}
								})}
							</div>
						) : null}
					</div>
					{showSettings ? (
						<>
							<div className="mobile-navigation-settings active">
								<div
									className="d-flex justify-content-start back-button-container"
									onClick={() => setShowSettings(false)}>
									<div className="back-button">
										<BackIcon />
										&nbsp;back
									</div>
								</div>
								<PermissionContainer permission="create_email">
									<AdminLink
										to="/settings/communications/"
										className="nav-item">
										<div className="d-flex flex-column">
											<div className="d-flex align-items-center justify-content-start p-4">
												<h4 className="nav-titles m-0 pl-4 text-capitalize">
													{translate("menu.communications")}
												</h4>
											</div>
										</div>
									</AdminLink>
								</PermissionContainer>
								<PermissionContainer permission="billing_access">
									<AdminLink to="/settings/billing/" className="nav-item">
										<div className="d-flex flex-column">
											<div className="d-flex align-items-center justify-content-start p-4">
												<h4 className="nav-titles m-0 pl-4 text-capitalize">
													{translate("menu.billing")}
												</h4>
											</div>
										</div>
									</AdminLink>
								</PermissionContainer>
								<NavLink
									onClick={() => setShowAccountMenu(false)}
									to="/profile/"
									className="nav-item">
									<div className="d-flex flex-column">
										<div className="d-flex align-items-center justify-content-start p-4">
											<h4 className="nav-titles m-0 pl-4 text-capitalize">
												{translate("menu.profile")}
											</h4>
										</div>
									</div>
								</NavLink>
							</div>
						</>
					) : (
						<>
							{is_student_only === false && (
								<>
									<AdminLink to="/dashboard/" className="nav-item">
										<div className="d-flex flex-column">
											<div className="d-flex align-items-center justify-content-start p-4">
												<h4 className="nav-titles m-0 pl-4 text-capitalize">
													{translate("menu.dashboard")}
												</h4>
											</div>
										</div>
									</AdminLink>
									<PermissionContainer
										permission={["view_user", "view_orgnode"]}>
										<AdminLink to="/organization/" className="nav-item">
											<div className="d-flex flex-column">
												<div className="d-flex align-items-center justify-content-start p-4">
													<h4 className="nav-titles m-0 pl-4 text-capitalize">
														{translate("menu.users_and_groups")}
													</h4>
												</div>
											</div>
										</AdminLink>
									</PermissionContainer>

									{!pastDueStatuses.blocking.includes(active_tree.status) && (
										<>
											<AdminLink to="/tracks/" className="nav-item">
												<div className="d-flex flex-column">
													<div className="d-flex align-items-center justify-content-start p-4">
														<h4 className="nav-titles m-0 pl-4 text-capitalize">
															{translate("menu.courses")}
														</h4>
													</div>
												</div>
											</AdminLink>
											<AdminLink to="/flows/" className="nav-item">
												<div className="d-flex flex-column">
													<div className="d-flex align-items-center justify-content-start p-4">
														<h4 className="nav-titles m-0 pl-4 text-capitalize">
															{translate("menu.flows")}
														</h4>
													</div>
												</div>
											</AdminLink>
										</>
									)}
								</>
							)}

							<NavLink
								onClick={() => dispatch(toggleMenu())}
								to="/my-courses/"
								className="nav-item">
								<div className="d-flex flex-column">
									<div className="d-flex align-items-center justify-content-start p-4">
										<h4 className="nav-titles m-0 pl-4 text-capitalize">
											{translate("menu.my_enrollments")}
										</h4>
									</div>
								</div>
							</NavLink>
							<div className="nav-item" onClick={() => setShowSettings(true)}>
								<div className="d-flex align-items-center justify-content-between p-4">
									<h4 className="m-0 pl-4 text-capitalize settings-link">
										{translate("menu.settings")}
									</h4>
									<div className="settings-arrow">
										<ForwardIcon />
									</div>
								</div>
							</div>
							<div
								className="nav-item logout-link"
								onClick={() => {
									logoutUser();
								}}>
								<div className="d-flex align-items-center justify-content-start p-4">
									<h4 className="m-0 pl-4 text-capitalize">
										{translate("menu.logout")}
									</h4>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="account-box">
				<div className="account-container">
					<div
						className="account-name"
						onClick={() => setShowAccountMenu((prev) => !prev)}>
						<p>{accountPortrait ? accountPortrait : userInitials}</p>
					</div>
					{showAccountMenu ? (
						<div
							className="close-edit-menu"
							onClick={() => setShowAccountMenu(false)}
						/>
					) : null}
					<div
						className={`account-menu ${
							showAccountMenu ? "active" : ""
						}`.trim()}>
						<div className="account-links d-flex justify-content-center flex-column align-items-center my-2">
							<Link
								className="account-name"
								to="/settings/profile/"
								onClick={() => setShowAccountMenu(false)}>
								{accountPortrait ? accountPortrait : userInitials}
							</Link>
							<p className="mb-0 font-weight-bold">
								{`${first_name} ${last_name}`.trim()}
							</p>
							<p className="mb-0 account-email">{email}</p>
							<small className="text-uppercase mb-4">
								{active_tree.label || active_tree.subdomain || ""}
							</small>
						</div>
						<div className="account-links">
							<NavLink className="account-link" to="/settings/profile/">
								{translate("my_account")}
							</NavLink>
							<a
								className="account-link"
								target="_blank"
								href="https://help.skillsuite.com/"
								rel="noreferrer">
								{translate("help")}
							</a>
							{/*<a className="account-link" rel="noreferrer" target="_blank" href="https://skillsuite.com/documentation" data-tip="Find answers to questions" data-for="profile-menu-tooltip">*/}
							{/*	Quick Help*/}
							{/*</a>*/}
							<ReactTooltip
								id="profile-menu-tooltip"
								backgroundColor={themeColors["blue"]}
								place="left"
								type="info"
								effect="solid"
								delayShow={300}
							/>
						</div>
						{associated_trees.length > 1 ? (
							<div className="account-links small-scrollbar pb-4">
								<h3>{translate("menu.switch_accounts")}</h3>
								{_.map(associated_trees, (tree) => {
									if (tree.id !== active_tree.id) {
										return (
											<div
												className="account"
												key={tree.id}
												onClick={() => handleAccountSelect(tree)}>
												<div className="account-name">
													{tree.subdomain[0]}
												</div>
												<div className="d-flex flex-column">
													<p className="account-username">
														{tree.label || tree.subdomain || ""}
													</p>
													<small className="account-subdomain">
														{translate("company")}
													</small>
												</div>
											</div>
										);
									}
								})}
							</div>
						) : null}
						<div className="logout-box">
							<p className="logout-account">
								{translate("login_message", { email })}
							</p>
							<div
								className="logout-link"
								onClick={() => {
									logoutUser();
								}}>
								{translate("menu.logout")}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
