import { useSelector } from "redux/index";
import "./styles.scss";

const StaticLoader = () => {
	const hierarchy = useSelector(({ hierarchy }) => hierarchy);
	const defaultLogoPath =
	"https://live-skillsuite.sfo2.digitaloceanspaces.com/live-skillsuite/media/org_node_logo/skillSuite_logo.jpg";

	const logoPath = hierarchy?.hierarchy?.[0]?.logo_file || defaultLogoPath;

	return (
		<div className="loader-container">
			<div className="loader-container-bkg" />
			<div className="circle-loader" />
			<img className="loading-img" src={logoPath} alt="Logo" />
		</div>
	);
};

export default StaticLoader;
