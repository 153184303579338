import { useEffect, useState } from "react";
import { AsyncGet } from "Global/api";
import { setEmailTemplates } from "store/emails/actions";
import { useSelector, useDispatch } from "redux/index";

export const useEmailTemplates = () => {
	const [fetched, setFetched] = useState(false);
	const emailTemplates = useSelector((state) => state?.emails?.templates || []);
	const dispatch = useDispatch();

	useEffect(() => {
		if (fetched === false) {
			AsyncGet("/v2/notification/email-template/").then((res) => {
				const data = res?.data?.results;

				setFetched(true);

				if (data?.length > 0) dispatch(setEmailTemplates(data));
			});
		}
	}, [dispatch, fetched, setFetched]);

	return [emailTemplates];
};
