import axios from "axios";

/**
 * @param {string} token
 */
//
const setAuthorizationToken = (token) => {
	if (token) {
		delete axios.defaults.headers.common["Authorization"];
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
};

export default setAuthorizationToken;
