import PropTypes from "prop-types";
import { BUTTON_SIZES, BUTTON_TYPES } from "./buttonConstants";
import "./button.scss";

const Button = (props) => {
	const {
		buttonType = "primary",
		icon,
		isDisabled = false,
		isIconBefore = true,
		isLoading = false,
		label,
		size = "regular",
		children,
		...extraProps
	} = props;

	const classes = `ss-button ${buttonType} ${size} ${
		isLoading ? "loading" : ""
	} ${extraProps?.className || ""}`;
	delete extraProps.className;

	return (
		<button {...extraProps} className={classes} disabled={isDisabled}>
			{icon && isIconBefore && (
				<div className="btn-icon btn-icon--left">{icon}</div>
			)}

			{typeof label === "string" && <span>{label}</span>}
			{["string", "object"].includes(typeof children) && (
				<span>{children}</span>
			)}

			{icon && !isIconBefore && (
				<div className="btn-icon btn-icon--right">{icon}</div>
			)}
			{isLoading && (
				<div className="loading-dots">
					<div className="loading-dot-1" />
					<div className="loading-dot-2" />
					<div className="loading-dot-3" />
				</div>
			)}
		</button>
	);
};

Button.propTypes = {
	buttonType: PropTypes.oneOf(BUTTON_TYPES),
	icon: PropTypes.element,
	isDisabled: PropTypes.bool,
	isIconBefore: PropTypes.bool,
	isLoading: PropTypes.bool,
	label: PropTypes.node.isRequired,
	size: PropTypes.oneOf(BUTTON_SIZES),
	children: PropTypes.any,
};
export default Button;
