/** @format */

import { lazy, Suspense, useMemo, useEffect, useState } from "react";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
	useRouteMatch,
} from "react-router-dom";
import { useSelector } from "redux/index";
import _ from "lodash";
import userflow from "userflow.js";
import axios from "axios";
import LogRocket from "logrocket";

import PrivateRoute from "./objects/PrivateRoute";
import ErrorBoundary from "oldDirectory/components/objects/ErrorBoundary";
import { GRADIENTS, COLORS } from "utilities/theme";
import {
	componentLoader,
	sendPageViewToGoogle,
	logoutUser,
	readCookie,
} from "Global/functions";
import SelectTree from "oldDirectory/containers/SelectTree/SelectTree";
import Page500 from "oldDirectory/components/objects/Page500";
import Page404 from "oldDirectory/components/objects/Page404";
import Integrations from "./integrations/shopify";
import SubscriptionValidation from "features/subscriptions/SubscriptionValidation";

import "./App.scss";
import { interceptorAppendInTree } from "Global/globalConfig";
import { Helmet } from "react-helmet";
import "assets/css/theme.scss";
import StaticLoader from "./objects/Loading";

const Organization = lazy(() =>
	componentLoader(() => import("oldDirectory/containers/Organization"))
);
const Home = lazy(() =>
	componentLoader(() => import("oldDirectory/components/admin/home"))
);
const Tracks = lazy(() =>
	componentLoader(() => import("oldDirectory/components/admin/tracks"))
);
const Account = lazy(() =>
	componentLoader(() => import("oldDirectory/components/account"))
);
const Settings = lazy(() =>
	componentLoader(() => import("oldDirectory/components/admin/settings"))
);
const MyCourses = lazy(() =>
	componentLoader(() =>
		import("oldDirectory/components/learning/tracks/MyCourses")
	)
);
const Flows = lazy(() =>
	componentLoader(() => import("features/flows/CompanyFlows"))
);
const SupplementalDocView = lazy(() =>
	componentLoader(() => import("oldDirectory/components/learning/documents"))
);
const CourseView = lazy(() =>
	componentLoader(() => import("oldDirectory/containers/tracks/CourseView"))
);

const ShopifySetupModal = lazy(() =>
	componentLoader(() => import("features/modals/FinishShopifySetup"))
);

const TermsAndConditions = lazy(() =>
	componentLoader(() =>
		import("oldDirectory/components/account/termsAndConditions")
	)
);
const PolicyInfo = lazy(() =>
	componentLoader(() => import("oldDirectory/components/account/policy-info"))
);

const App = () => {
	const history = useHistory();
	const location = useLocation();

	const [themeColors, setThemeColors] = useState({
		"--main-color": COLORS.main,
		"--secondary-color": COLORS.secondary,
		"--dashboard-button-1-color": COLORS.dashboard.button["1"],
		"--dashboard-button-2-color": COLORS.dashboard.button["2"],
		"--dashboard-button-3-color": COLORS.dashboard.button["3"],
		"--dashboard-button-4-color": COLORS.dashboard.button["4"],
		// "--dashboard-button-2-color": "#f72585",
		// "--dashboard-button-3-color": "#7209b7",
		// "--dashboard-button-4-color": "#4361ee",
		// "--page-header-color": "linear-gradient(to right,#1ddaff 0%,#4361ee 100%)",
		"--page-header-color": GRADIENTS[COLORS.main],
	});

	const [themeColorsString, setThemeColorsString] = useState(null);

	const appClasses = useMemo(
		() => _.get(location, "pathname", "").split("/").join(" ").trim(),
		[location]
	);

	const {
		userData = {},
		is_student_only,
		active_tree = {},
	} = useSelector(({ auth }) => auth || {});

	useEffect(() => {
		const interceptorId = axios.interceptors.request.use(
			interceptorAppendInTree(active_tree.id, process.env.REACT_APP_BACKEND_URL)
		);
		return () => {
			axios.interceptors.request.eject(interceptorId);
		};
	}, [active_tree.id]);

	// GA/page view event attachment
	useEffect(() => {
		if ("production" === process.env.NODE_ENV) {
			const detachListener = history.listen(sendPageViewToGoogle);
			return () => {
				detachListener();
			};
		}
	}, [history]);

	useEffect(() => {
		const jwtAccessCookie = readCookie("jwtAccess");
		if (active_tree?.id && jwtAccessCookie) {
			axios
				.get(`/v2/organization/theme/${active_tree.id}/`)
				.then(({ data: response }) => {
					if (response?.theme) return formatterThemeColors(response.theme);
				});
		}
	}, [active_tree.id]);

	// LogRocket & UserFlow init
	useEffect(() => {
		let didCancel = false;
		if (
			!didCancel &&
			active_tree?.plan?.provider &&
			"production" === process.env.NODE_ENV
		) {
			if (process.env.REACT_APP_LOG_ROCKET_ID) {
				LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID);
				LogRocket.identify(userData.id, {
					name: userData.first_name,
					email: userData.email,
				});
			}
			if (process.env.REACT_APP_USERFLOW_KEY) {
				userflow.init(process.env.REACT_APP_USERFLOW_KEY);
				userflow
					.identify(userData.id, {
						name: userData.first_name,
						email: userData.email,
						signed_up_at: userData.date_joined,
						is_student_only,
						provider: active_tree.plan.provider,
					})
					.catch((error) => {
						console.error(error);
						userflow.reset();
					})
					.finally(() => {
						if (userflow.isIdentified()) {
							userflow.setCustomNavigate((url) => history.push(url));
						}
					});
			}
		}
		return () => {
			didCancel = true;
			if ("production" === process.env.NODE_ENV) {
				userflow.reset();
			}
		};
	}, [active_tree, userData]);

	useEffect(() => {
		if (themeColors !== null) {
			let themeString = "";

			for (let key in themeColors) {
				const hasProperty = Object.prototype.hasOwnProperty.call(
					themeColors,
					key
				);
				const hasValue = themeColors[key] !== null;

				if (hasProperty && hasValue)
					themeString += `${key}: ${themeColors[key]}; `;
			}

			setThemeColorsString(themeString);
		}
	}, [themeColors, setThemeColorsString]);

	const formatterThemeColors = (theme) => {
		const getValue = (value, key) => {
			if (!value) return {};

			return { [key]: value };
		};

		const trackViewOverview = document.querySelector(".track-view");
		const preGradientColor = !trackViewOverview
			? theme.main_color
			: getComputedStyle(trackViewOverview).getPropertyValue("--theme-color");
		const gradientColor =
			preGradientColor?.length === 0 ? theme.main_color : preGradientColor;

		const updatedTheme = {
			...getValue(theme.main_color || theme.custom_main_color, "--main-color"),
			...getValue(
				theme.secondary_color || theme.custom_secondary_color,
				"--secondary-color"
			),
			...getValue(
				theme.button_1_color || theme.custom_button_1_color,
				"--dashboard-button-1-color"
			),
			...getValue(
				theme.button_2_color || theme.custom_button_2_color,
				"--dashboard-button-2-color"
			),
			...getValue(
				theme.button_3_color || theme.custom_button_3_color,
				"--dashboard-button-3-color"
			),
			...getValue(
				theme.button_4_color || theme.custom_button_4_color,
				"--dashboard-button-4-color"
			),
			...getValue(
				theme.custom_header_color ||
					GRADIENTS.COLORS[gradientColor] ||
					theme.header_color,
				"--page-header-color"
			),
		};

		return setThemeColors(updatedTheme);
	};

	const userWithoutRole = Object.keys(active_tree).length === 0;
	const isLogout = useRouteMatch({
		path: "/logout",
		exact: true,
	});

	if (isLogout) logoutUser();

	return (
		<ErrorBoundary>
			<Helmet
				htmlAttributes={{
					style: themeColorsString,
				}}
			/>
			<div className={`app ${appClasses}`.trim()}>
				<Suspense fallback={<StaticLoader />}>
					<Switch>
						<Route path="/account/">
							<Account />
						</Route>
						<Route path="/terms-and-conditions">
							<TermsAndConditions />
						</Route>
						<Route path="/policy-policy">
							<PolicyInfo />
						</Route>
						{/* <Route path="/integrations">
							<Integrations />
						</Route> */}
						<Route path="/500/">
							<Page500 />
						</Route>
						<Route path="/404">
							<Page404 />
						</Route>
						{userWithoutRole && (
							<Route path="/my-courses/">
								<SubscriptionValidation>
									<MyCourses />
								</SubscriptionValidation>
							</Route>
						)}
						<PrivateRoute path="/">
							<SubscriptionValidation>
								<Switch>
									<Route path="/choose-subdomain">
										<SelectTree />
									</Route>
									<Route
										path={[
											"/tracks/preview/:trackID/",
											"/my-courses/:registrationID/",
										]}>
										<CourseView />
									</Route>
									<Route path="/">
										<ShopifySetupModal />
										<div className="contentarea" id="contentarea">
											<div className="content-container">
												{!is_student_only ? (
													<Switch>
														<Route exact path="/dashboard/">
															<Home />
														</Route>
														<Redirect from="/dashboard/*" to="/dashboard/" />
														<Route path="/organization/">
															<Organization />
														</Route>
														<Route path="/tracks/">
															<Tracks />
														</Route>
														<Route path="/settings/">
															<Settings />
														</Route>
														<Route path="/my-courses/">
															<MyCourses />
														</Route>
														<Route path="/flows/">
															<Flows />
														</Route>
														<Route path="/documents/supplemental/:courseID/">
															<SupplementalDocView />
														</Route>
														<Redirect from="/" to="/dashboard/" />
													</Switch>
												) : (
													<Switch>
														<Route path="/settings/">
															<Settings />
														</Route>
														<Route path="/my-courses/">
															<MyCourses />
														</Route>
														<Route path="/documents/supplemental/:courseID/">
															<SupplementalDocView />
														</Route>
														<Redirect from="/" to="/my-courses/" />
													</Switch>
												)}
											</div>
										</div>
									</Route>
								</Switch>
							</SubscriptionValidation>
						</PrivateRoute>
					</Switch>
				</Suspense>
				<footer className="center-crap mb-5 mt-2 main-footer">
					<img
						alt="Skill Suite"
						width="300"
						height="100%"
						src="https://live-skillsuite.sfo2.digitaloceanspaces.com/live-skillsuite/media/org_node_logo/skillSuite_logo.jpg"
					/>
				</footer>
			</div>
		</ErrorBoundary>
	);
};
export default App;
