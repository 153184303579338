import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "redux/index";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

import { forceFlowToRun, setIntegrations } from "store/flows/actions";
import { usePrevious, useTranslation } from "utilities/hooks";
import { successToast, errorToast } from "Global/functions";
import { Get } from "Global/api";

export const useFlows = () => {
	return useSelector((state) => state.flows.flows) || [];
};

export const useActiveFlow = () => {
	const flowId = useFlowID();

	return useSelector(
		(state) =>
			state.flows.flows.find(
				(item) => item.id === flowId || item.localID === flowId
			) || null
	);
};

export const useFlowID = () => {
	const location = useLocation();
	const [flowId, setFlowId] = useState(location.hash.split("=")[1]);

	useEffect(() => {
		const flowIdFromLocation = location.hash.split("=")[1];

		setFlowId(flowIdFromLocation || null);
	}, [location.hash, setFlowId]);

	const parsed = parseInt(flowId);

	if (typeof flowId === "string" && isNaN(flowId)) return flowId;

	if (flowId === null || !flowId) return null;

	return parsed;
};

export const useHighlightedFlow = (usePreviousStatus = false) => {
	const highlightedFlowId = useSelector(({ flows }) => flows.highlightedFlowId);
	const flow = useActiveFlow();

	const highlightedFlow = flow?.children?.find(
		(item) =>
			item.id === highlightedFlowId || item.localID === highlightedFlowId
	);
	const previousFlow = usePrevious(highlightedFlow);

	if (usePreviousStatus) return [highlightedFlow, previousFlow];

	return highlightedFlow;
};

export const useFlowByID = (flowId) => {
	const activeFlow = useActiveFlow();

	const flow =
		activeFlow?.children?.find(
			(item) => item.id === flowId || item.localID === flowId
		) || null;

	return { ...flow };
};

export const useIntegrations = (id) => {
	const integrations = useSelector((state) => state.flows.integrations);
	const dispatch = useDispatch();
	const updateIntegrations = (data) => dispatch(setIntegrations(data));

	useEffect(() => {
		Get("/v2/integration", (res) => {
			if (res.data.results.length === 0) return null;

			return updateIntegrations(
				res.data.results.map((item) => ({
					label: item.label,
					key: item.label.toLowerCase(),
					value: item.id,
				}))
			);
		});
	}, [updateIntegrations]);

	if (id) return integrations.find((item) => item.value === parseInt(id));

	return [integrations];
};

export const useHubSpotOptions = (key) => {
	const [t] = useTranslation(
		"courses.assignCourse.flowEditor.leftBar.integration"
	);

	const hubSpotOptions = [
		{
			label: t("hubspot.options.createContact"),
			value: "hubspot_create_contact",
		},
	];

	if (key) return hubSpotOptions.find((item) => item.value === key);

	return hubSpotOptions;
};

export const useForceRunFlow = (flowID) => {
	const [translate] = useTranslation("courses.assignCourse.flows.tableItem");
	const dispatch = useDispatch();

	const callback = useCallback(
		(status) => {
			if (status)
				return successToast(
					translate("force-flow.success.title"),
					translate("force-flow.success.text")
				);

			return errorToast(
				translate("force-flow.error.title"),
				translate("force-flow.error.text")
			);
		},
		[translate]
	);

	const forceRunFlow = useCallback(async () => {
		const result = await Swal.fire({
			title: translate("force-flow.warning.title"),
			text: translate("force-flow.warning.text"),
			icon: "warning",
			showCancelButton: true,
		});

		if (result.isConfirmed) return dispatch(forceFlowToRun(flowID, callback));
	}, [dispatch, callback, flowID, translate]);

	return [forceRunFlow];
};
