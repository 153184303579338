import { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "redux/index";

import { createCookie, getSubdomain, readCookie } from "Global/functions";
import { chooseTree } from "features/subscriptions/utils";

import "./SelectTree.scss";

// if subdomain matches one of the trees in their associated_trees list then let them in and set the active_tree to that,
// if they change subdomain then send them over to the subdomain ,
//  If they don't have the subdomain in their associated_trees list then
const SelectTree = () => {
	const history = useHistory();
	const associated_trees = useSelector(({ auth }) => auth.associated_trees);

	useEffect(() => {
		let didCancel = false;
		if (
			!didCancel &&
			!(!process.env.NODE_ENV || process.env.NODE_ENV === "development")
		) {
			domainMatch();
		}
		return () => {
			didCancel = true;
		};
	}, []);

	const domainMatch = () => {
		const subdomain = getSubdomain();
		const myTree = JSON.parse(readCookie("tree") || "{}");

		if (_.isEmpty(myTree)) {
			_.forEach(associated_trees, (tree) => {
				if (tree.subdomain === subdomain) {
					createCookie("tree", JSON.stringify(tree), null, null, false, true);
					return false;
				}
			});
		} else {
			if (myTree.subdomain !== subdomain) {
				_.forEach(associated_trees, (tree) => {
					if (tree.subdomain === subdomain) {
						createCookie("tree", JSON.stringify(tree));
						window.location.href = "/dashboard";
						return false;
					}
				});
				history.push("/choose-subdomain/");
			}
		}
	};

	return (
		<Route path="/choose-subdomain/">
			<div className="select-tree-container text-center">
				<div className="container select-box">
					<h4>
						<strong>
							Looks like you have access to multiple profiles.
							<br />
							Please select which profile you would like to enter
						</strong>
					</h4>
					<ul className="list-style-none subdomain-list p-0">
						{_.map(associated_trees, (tree, i) => (
							<li
								key={i}
								className="border-top py-3 bg-blue-hover cursor"
								onClick={() => chooseTree(tree)}>
								<strong> {tree.label || tree.subdomain}</strong>
							</li>
						))}
					</ul>
				</div>
			</div>
		</Route>
	);
};

export default SelectTree;
