import { useSelector } from "redux/index";
import { Link } from "react-router-dom";
import { useTranslation } from "utilities/hooks";

const PastDueBanner = () => {
	const { permissions } = useSelector(({ permissions }) => permissions);
	const { active_tree } = useSelector(({ auth }) => auth);
	const [ translate ] = useTranslation("subscriptions.messages");
	const bannerDict = {
		active: {
			label: null,
		},
		trial_ended: {
			billingLabel: translate("trialEnded.billingLabel"),
			standardLabel: translate("trialEnded.standardLabel"),
		},
		past_due_0: {
			billingLabel: translate("pastDue0.billingLabel"),
			standardLabel: translate("pastDue0.standardLabel"),
		},
		past_due_1: {
			billingLabel: translate("pastDue1.billingLabel"),
			standardLabel: translate("pastDue1.standardLabel"),
		},
		past_due_2: {
			billingLabel: translate("pastDue2.billingLabel"),
			standardLabel: translate("pastDue2.standardLabel"),
		},
		past_due_3: {
			billingLabel: translate("pastDue3.billingLabel"),
			standardLabel: translate("pastDue3.standardLabel"),
		},
		past_due_4: {
			billingLabel: translate("pastDue4.billingLabel"),
			standardLabel: translate("pastDue4.standardLabel"),
		},
		canceled: {
			billingLabel: translate("canceled.billingLabel"),
			standardLabel: translate("canceled.standardLabel"),
		},
	};

	return (
		<div className="div-container" id="bannerContainer">
			<div className="banner-box">
				<div className="banner">
					{permissions.billing_access ? (
						<>
							<strong>{bannerDict[active_tree.status].billingLabel}</strong>
							<Link
								className="btn btn-small banner-btn-white"
								to="/settings/billing/?trial_ended">
								Update Billing
							</Link>
						</>
					) : (
						<strong>{bannerDict[active_tree.status].standardLabel}</strong>
					)}
				</div>
			</div>
		</div>
	);
};

export default PastDueBanner;
