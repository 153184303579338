import { useSelector } from "redux/index";
import { chooseTree } from "features/subscriptions/utils";
import { map, filter } from "lodash";
import { logoutUser } from "Global/functions";
import Button from "ComponentLibrary/Button";
import React from "react";

const TreeCanceled = () => {
	const { active_tree, associated_trees } = useSelector(({ auth }) => auth);

	return (
		<div className="tree-terminated-container">
			<div className="card">
				<h2 className="tree-terminated-header">This account is cancelled</h2>
				{associated_trees.length > 1 && (
					<div className="select-box">
						<p>Please select another account:</p>
						<ul className="list-style-none subdomain-list p-0">
							{map(
								filter(associated_trees, (tree) => tree.id !== active_tree.id),
								(tree, i) => (
									<li
										key={i}
										className="border-top py-3 bg-blue-hover cursor"
										onClick={() => chooseTree(tree)}>
										<strong> {tree.label || tree.subdomain}</strong>
									</li>
								)
							)}
						</ul>
					</div>
				)}
			</div>
			<Button
				buttonType="text"
				className="mt-4"
				label="Log out"
				onClick={() => logoutUser()}
			/>
		</div>
	);
};

export default TreeCanceled;
