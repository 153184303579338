import { CONSTANTS } from "./reducer";

export function removeCourse(courseID) {
	return {
		type: CONSTANTS.REMOVE_COURSE,
		payload: courseID,
	};
}

export function setCourses(courses) {
	return {
		type: CONSTANTS.SET_COURSES,
		payload: courses,
	};
}

export function addCourse(course) {
	return {
		type: CONSTANTS.ADD_COURSE,
		payload: course,
	};
}
