import { useEffect, useState } from "react";
import { stringify } from "query-string";
import axios from "axios";

import { AsyncGet } from "Global/api";
import { useDebounce } from "./useDebounce";

const useInfiniteScroll = (url, params, query) => {
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const debounceSearchQuery = useDebounce(query, 300);

	const removeItem = (index) => {
		let newData = [...data];
		newData.splice(index, 1);
		setData([...newData]);
	};

	useEffect(() => {
		setData([]);
		setHasMore(false);
		setLoading(true);
	}, [debounceSearchQuery]);

	useEffect(() => {
		const paramsString = "?" + stringify(params) + `&search=${query}`;
		setError(false);
		setLoading(true);
		AsyncGet(url + paramsString, 1)
			.then((res) => {
				setData((prevData) => [...new Set([...prevData, ...res.data.results])]);
				setHasMore(res.data.page < res.data.pages);
				setLoading(false);
			})
			.catch((err) => {
				if (axios.isCancel(err)) return;
				setLoading(false);
				setError(true);
			});
	}, [params.page, debounceSearchQuery]);
	return { loading, data, error, hasMore, setData, removeItem };
};

export { useInfiniteScroll };

const useUnsplashInfiniteScroll = (url, params, query) => {
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const debounceSearchQuery = useDebounce(query, 300);
	useEffect(() => {
		setData([]);
		setHasMore(false);
	}, [debounceSearchQuery]);

	useEffect(() => {
		const paramsString = "?" + stringify(params) + `&query=${query}`;
		setError(false);
		if (debounceSearchQuery || debounceSearchQuery !== "") {
			setLoading(true);
			AsyncGet(url + paramsString, 1)
				.then((res) => {
					setData((prevData) => [
						...new Set([...prevData, ...res.data.results]),
					]);
					setHasMore(params.page < res.data.total_pages);
					setLoading(false);
				})
				.catch(() => {
					setError(true);
				});
		}
	}, [params.page, debounceSearchQuery]);
	return { loading, data, error, hasMore };
};

export { useUnsplashInfiniteScroll };

export const useGiphyInfiniteScroll = (url, params, query) => {
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [error, setError] = useState(false);
	const debounceSearchQuery = useDebounce(query, 300);
	useEffect(() => {
		setData([]);
		setHasMore(false);
	}, [debounceSearchQuery]);

	useEffect(() => {
		const paramsString = `?q=${query}&` + stringify(params);
		setError(false);
		if (debounceSearchQuery || debounceSearchQuery !== "") {
			setLoading(true);
			AsyncGet(url + paramsString, 1)
				.then(({ data }) => {
					setData((prevData) => [...new Set([...prevData, ...data.data])]);
					setHasMore(data.pagination.offset < data.pagination.total_count);
					setLoading(false);
				})
				.catch(() => {
					setError(true);
				});
		}
	}, [params.offset, debounceSearchQuery]);
	return { loading, data, error, hasMore };
};
