/** @format */

import "./Global/Polyfills";
import messages from "./lang/en-US.json";
import { flattenMessages } from "./lang/helperFunctions";

import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DevToolsProvider from "redux/DevToolsProvider";

import App from "./oldDirectory/components/App";
import ErrorToast from "./components/ErrorToast/ErrorToast";
import history from "Global/history";
import * as serviceWorker from "./serviceWorker";
import { accessTokenValidity } from "Global/authenticated";
import "./Global/globalConfig";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/global.scss";

import { decodeUser } from "store/auth/actions";
import { fetchPermissions } from "store/permissions/actions";
import { fetchHierarchy } from "store/hierarchy/actions";

import { Provider, dispatch } from "redux/index";
import { store } from "store";

accessTokenValidity(() => {
	dispatch(decodeUser());
	dispatch(fetchPermissions());
	dispatch(fetchHierarchy());
});

const typeLabels = {
	form: "Quiz",
	course: "Online Course",
	track: "Course",
	automation: "Automation",
};

global.typeLabels = typeLabels;

ReactDOM.render(
	<DevToolsProvider>
		<IntlProvider
			messages={flattenMessages(messages)}
			locale="en"
			defaultLocale="en">
			<Provider store={store} name="skillsuite_platform">
				<Router history={history}>
					<ToastContainer limit={1} />
					<ErrorToast />
					<App />
				</Router>
			</Provider>
		</IntlProvider>
	</DevToolsProvider>,
	document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
