import PropTypes from "prop-types";

// -- Begin: Constants --
const ENV = {
	SIT: "sit",
	UAT: "uat",
	PRD: "live",
};

const FEATURES = {
	COURSE: {
		COMPONENTS: {
			UPLOAD: "COURSE.COMPONENTS.UPLOAD"
		}
	},
};

// Example: [FEATURE.FLOWS]: [ENV.SIT, ENV.PRD]
const TOGGLES_ON = {
	[FEATURES.COURSE.COMPONENTS.UPLOAD]: [ENV.SIT, ENV.UAT, ENV.PRD],
};
// -- End: Constants --

const isFeatureToggledOn = (feature) => {
	if (!feature) {
		return false;
	}

	const currentEnv = process.env.REACT_APP_FEATURE_ENV;
	return TOGGLES_ON[feature].some((feat) => feat === currentEnv);
};

const RenderToggledFeature = (props) => {
	const { children, feature } = props;

	if (!feature) {
		return null;
	}

	const currentEnv = process.env.REACT_APP_FEATURE_ENV;
	const isFeatureToggledOn = TOGGLES_ON[feature].some(
		(feat) => feat === currentEnv
	);

	if (!isFeatureToggledOn) {
		return null;
	}

	return children;
};
RenderToggledFeature.propTypes = {
	children: PropTypes.node.isRequired,
	feature: PropTypes.string.isRequired,
};

export { FEATURES, isFeatureToggledOn, RenderToggledFeature };
