import React from "react";
import PropTypes from "prop-types";

const Toasts = ({ image, title, subtitle }) => {
	return (
		<div className="d-flex align-items-center">
			<img src={image} height="30" width="30" alt="toast icon" />
			<div className="d-flex flex-column ml-3">
				<p className="mb-1 text-white font-weight-bold">{title}</p>
				<small className="">{subtitle}</small>
			</div>
		</div>
	);
};

Toasts.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
};

export default Toasts;
