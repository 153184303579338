import { useState, useEffect } from "react";
import { AsyncGet } from "Global/api";
import { setCourses } from "store/courses/actions";
import { useSelector, useDispatch } from "redux/index";

export const useCourses = (optionsArray = false) => {
	const [isFetching, setIsFetching] = useState(false);
	const courses = useSelector((state) => state.courses.courses);
	const dispatch = useDispatch();

	useEffect(() => {
		if (
			isFetching === false &&
			(typeof courses?.length === "undefined" || courses?.length < 1)
		) {
			setIsFetching(true);

			AsyncGet("/v2/course/").then((res) => {
				const data = res?.data?.results;

				setIsFetching(false);

				if (data?.length > 0) return dispatch(setCourses(data));
			});
		}
	}, [setIsFetching, dispatch, isFetching, courses?.length]);

	if (optionsArray === false) return [courses || []];

	return [
		courses.map((course) => ({
			label: course.label,
			value: course.id,
		})),
	];
};
