const initialState = {
	templates: [],
};

export const CONSTANTS = {
	REMOVE_EMAIL_TEMPLATE: "REMOVE_EMAIL_TEMPLATE",
	SET_EMAIL_TEMPLATES: "SET_EMAIL_TEMPLATES",
	ADD_EMAIL_TEMPLATE: "ADD_EMAIL_TEMPLATE",
	LOGOUT: "LOGOUT",
};

const emailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.REMOVE_EMAIL_TEMPLATE: {
			return {
				...state,
				templates: state.templates.filter(
					(emailTemplate) => emailTemplate.id !== action.payload
				),
			};
		}
		case CONSTANTS.SET_EMAIL_TEMPLATES: {
			return {
				...state,
				templates: action.payload,
			};
		}
		case CONSTANTS.ADD_EMAIL_TEMPLATE: {
			return {
				...state,
				templates: [...state.templates, action.payload],
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default emailsReducer;