import { useEffect, useCallback } from "react";
import { AsyncGet } from "Global/api";
import { setUsers, setUsersLoading } from "store/users/actions";
import { useSelector, useDispatch } from "redux/index";

export const useUsers = () => {
	const users = useSelector((state) => state.users.users);
	const isLoadingUsers = useSelector((state) => state.users.isLoadingUsers);
	const dispatch = useDispatch();

	const setUsersDispatch = useCallback(
		(data) => {
			return dispatch(setUsers(data));
		},
		[dispatch]
	);

	const setUsersLoadingDispatch = useCallback(
		(value) => {
			return dispatch(setUsersLoading(value));
		},
		[dispatch]
	);

	useEffect(() => {
		if (
			isLoadingUsers === false &&
			(typeof users?.length === "undefined" || users?.length < 1)
		) {
			setUsersLoadingDispatch(true);

			AsyncGet("/v2/user/").then((res) => {
				setUsersLoadingDispatch(false);

				const data = res?.data?.results;

				if (data?.length > 0) setUsersDispatch(data);
			});
		}
	}, [users, setUsersDispatch, setUsersLoadingDispatch]);

	return [users, isLoadingUsers];
};
