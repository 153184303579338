import { useEffect, useState, useCallback } from "react";
import _ from "lodash";

import { useThunkReducer } from "./useThunkReducer";
import { AsyncGet } from "Global/api";

/**
 * @param {{isLoading: boolean, isError: boolean, data: object}} state
 * @param {{type: ("FETCH_INIT"|"FETCH_SUCCESS"|"FETCH_FAILURE"|"ADD_ITEM"|"REMOVE_ITEM"|"MODIFY_ITEM"|"MAP_ITEMS"|"REDUCE_ITEMS", payload: *}} action
 * @returns {{isLoading: boolean, isError: boolean, data: object}}
 */
const dataFetchReducer = (state, action) => {
	const { type, payload = {} } = action;
	switch (type) {
		case "FETCH_INIT":
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case "FETCH_SUCCESS":
			return {
				...state,
				isLoading: false,
				isError: false,
				data: payload,
			};
		case "FETCH_FAILURE":
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		case "ADD_ITEM":
			return {
				...state,
				data: {
					...state.data,
					results: [...state.data.results, payload],
				},
			};
		case "REMOVE_ITEM":
			return {
				...state,
				data: {
					...state.data,
					results: _.filter(state.data.results, (item) => {
						return item.id !== payload.id;
					}),
				},
			};
		case "MODIFY_ITEM": {
			const { newValue, compareProperties: paths } = payload;
			return {
				...state,
				data: {
					...state.data,
					results: _.map(state.data.results, (item) =>
						_.isEqual(_.pick(item, paths), _.pick(newValue, paths))
							? { ...item, ...newValue }
							: item
					),
				},
			};
		}
		case "MAP_ITEMS":
			return {
				...state,
				data: {
					...state.data,
					results: _.map(state.data.results, payload),
				},
			};
		case "FILTER_ITEMS":
			return {
				...state,
				data: {
					...state.data,
					results: _.filter(state.data.results, payload),
				},
			};
		case "REDUCE_ITEMS":
			return {
				...state,
				data: {
					...state.data,
					results: _.reduce(state.data.results, payload, []),
				},
			};
		default:
			throw new Error();
	}
};

/**
 * Hook used to send api requests
 * @param {string} initialUrl
 * @param {object} initialData
 * @returns {Array}
 **/
const useDataApi = (initialUrl, initialData) => {
	const [url, setUrl] = useState(initialUrl);
	const [state, dispatch] = useThunkReducer(dataFetchReducer, {
		isLoading: true,
		isError: false,
		data: initialData,
	});
	const [updateCount, setUpdateCount] = useState(0);

	useEffect(() => {
		let didCancel = false;
		const doFetch = (url) => {
			dispatch({ type: "FETCH_INIT" });
			AsyncGet(url)
				.then((res) => {
					if (!didCancel) {
						dispatch({ type: "FETCH_SUCCESS", payload: res.data });
					}
				})
				.catch((err) => {
					if (!didCancel) {
						dispatch({ type: "FETCH_FAILURE" });
					}
					console.error(err);
				});
		};
		if (!didCancel && url) {
			doFetch(url);
		}
		return () => {
			didCancel = true;
		};
	}, [dispatch, url, updateCount]);

	const __setUrl = useCallback((fetchUrl, shouldRefetch = false) => {
		if (shouldRefetch === true) {
			setUpdateCount((newUpdateCount) => newUpdateCount + 1);
		}
		setUrl(fetchUrl);
	}, []);

	return [state, __setUrl, dispatch];
};

export { useDataApi };
