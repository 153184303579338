const initialState = {
	permissions: {},
	loading: true,
	error: null,
};

export const CONSTANTS = {
	PERMISSIONS_SUCCESS: "PERMISSIONS_SUCCESS",
	PERMISSIONS_REQUEST: "PERMISSIONS_REQUEST",
	PERMISSIONS_CATCH: "PERMISSIONS_CATCH",
	LOGOUT: "LOGOUT",
};

const permissionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONSTANTS.PERMISSIONS_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case CONSTANTS.PERMISSIONS_SUCCESS: {
			return {
				...state,
				permissions: action.payload,
				loading: false,
			};
		}
		case CONSTANTS.PERMISSIONS_CATCH: {
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		}
		case CONSTANTS.LOGOUT: {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export default permissionsReducer;