import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "redux/index";
import { toggleMenu } from "store/menu/actions";

const AdminLink = (props) => {
	const is_student_only = useSelector(({ auth }) => auth.is_student_only);
	const dispatch = useDispatch();

	if (is_student_only) {
		return null;
	} else
		return (
			<NavLink onClick={() => dispatch( toggleMenu() )} {...props}>
				{props.children}
			</NavLink>
		);
};

export default AdminLink;
