export const GRADIENTS = {
	CLASSES: {
		"#1ddaff": "gradient-light-blue",
		"#4361ee": "gradient-blue",
		"#7209b7": "gradient-purple",
		"#db2ab2": "gradient-fuchsia",
		"#f72585": "gradient-pink",
		"#ef766a": "gradient-salmon",
		"#ff004e": "gradient-red",
		"#c3033d": "gradient-dark-red",
		"#93e29e": "gradient-green",
		"#ffe17c": "gradient-yellow",
	},
	COLORS: {
		"#1ddaff": "linear-gradient(90deg, #3cdfff 0%, #1ddaff 50%, #17adcb 100%)",
		"#4361ee": "linear-gradient(90deg, #7188f6 0%, #4361ee 50%, #2c45b9 100%)",
		"#7209b7": "linear-gradient(90deg, #a34cdd 0%, #7209b7 50%, #5c0a91 100%)",
		"#db2ab2": "linear-gradient(90deg, #f562d3 0%, #db2ab2 50%, #98157a 100%)",
		"#f72585": "linear-gradient(90deg, #f779b3 0%, #f72585 50%, #a8185a 100%)",
		"#ef766a": "linear-gradient(90deg, #f9aaa2 0%, #ef766a 50%, #c45f54 100%)",
		"#ff004e": "linear-gradient(90deg, #fc6493 0%, #ff004e 50%, #c3043f 100%)",
		"#c3033d": "linear-gradient(90deg, #e93269 0%, #c3033d 50%, #90022d 100%)",
		"#93e29e": "linear-gradient(90deg, #a3eeaa 0%, #93e29e 50%, #79c383 100%)",
		"#ffe17c": "linear-gradient(90deg, #ffe89b 0%, #ffe17c 50%, #ddc05f 100%)",
	},
};

export const COLORS = {
	main: "#1ddaff",
	secondary: "#f72585",
	dashboard: {
		button: ["#1ddaff", "#f72585", "#7209b7", "#4361ee"],
	},
};
