import React from "react";
import PropTypes from "prop-types";
import Page500 from "oldDirectory/components/objects/Page500";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}
	componentDidCatch() {
		// Display fallback UI
		if (this.state.hasError === false) {
			this.setState({ hasError: true });
		}
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (process.env.NODE_ENV !== "development") return true;
		if (nextState.hasError === true || this.state.hasError === true)
			return false;
		return true;
	}

	render() {
		if (this.state.hasError && process.env.NODE_ENV !== "development") {
			// You can render any custom fallback UI
			return <Page500 />;
		}
		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.any,
};

export default ErrorBoundary;
